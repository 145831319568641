import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "@app/globals";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import {
	ClientInstallInfo,
	MyProductInfo,
	ClientProductInfo,
} from "@app/Models/ClientInstallInfo";
import { ProductListService } from "./product-list.service";
import { ProductItem } from "@app/Models/ProductItem";
import { ProductDownloadService } from "./product-download.service";

@Injectable({
	providedIn: "root",
})
export class ClientInstallInfoService {
	constructor(
		private http: HttpClient,
		private globals: Globals,
		private productService: ProductListService,
		private downloadUrlService: ProductDownloadService
	) {}

	async GetInstallInfoList(userid: string): Promise<ClientInstallInfo[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.ClientInstallInfoEndpoint;
		if (userid != null && userid !== undefined) {
			urlValue = this.globals.AddParamToUrl(urlValue, "user_id", userid);
		}

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);

		let x: any = await this.http
			.get<ApiResponse>(urlValue)
			.toPromise()
			.catch((err: HttpErrorResponse) => {
				this.globals.HttpErrorResponseHandling(err.status);
			});

		return JSON.parse(x.body);
	}

	async GetMyProductsList(userid: string): Promise<MyProductInfo[]> {
		const productCollection: MyProductInfo[] = [];

		const installInfoList = await this.GetInstallInfoList(userid);
		let clientInstallIinfoList: ClientProductInfo[] = [];

		for (const x of installInfoList) {
			clientInstallIinfoList = clientInstallIinfoList.concat(
				x.product_version_info
			);
		}

		const productIdCollection: string[] = clientInstallIinfoList.map(
			(x) => x.product_id
		);

		const productIdString: string = productIdCollection.join(",");

		const productInfoCollection: ProductItem[] =
			await this.productService.getProducts(productIdString, "all");

		installInfoList.forEach((element) => {
			element.product_version_info.forEach((x) => {
				if (this.globals.MarketplaceClientProductId !== x.product_id) {
					const product = new MyProductInfo();

					product.user_id = element.user_id;
					product.machine_name = element.machine_name;
					product.mipro_version = element.mipro_version;
					product.product_version_used = x.version;

					const prod = productInfoCollection.find(
						(p) => p.product_id === x.product_id
					);

					if (prod != null) {
						product.product_info = prod;
						productCollection.push(product);
					}
				}
			});
		});

		return productCollection.sort((product1, product2) =>
			this.SortProducts(product1, product2)
		);
	}

	GetProductsWithUpdates(fullProductList: MyProductInfo[]) {
		const updateProductCollection: MyProductInfo[] = [];

		fullProductList.forEach(async (element) => {
			if (
				element.machine_name === this.globals.MachineName &&
				element.mipro_version === this.globals.ProVersion &&
				element.product_version_used < element.product_info.version
			) {
				element.download_url = await this.downloadUrlService.getProductDownloadurl(
					element.product_info.product_id
				);

				updateProductCollection.push(element);
			}
		});

		return updateProductCollection;
	}

	async asyncForEach(array, callback) {
		if (array != null) {
			for (let index = 0; index < array.length; index++) {
				await callback(array[index], index, array);
			}
		}
	}

	SortProducts(product1: MyProductInfo, product2: MyProductInfo): number {
		let sortVal = 0;

		if (product1.product_info != null && product2.product_info != null) {
			const prodName1 = product1.product_info.product_name.toLowerCase();
			const prodName2 = product2.product_info.product_name.toLowerCase();

			if (prodName1 > prodName2) {
				sortVal = 1;
			} else if (prodName1 < prodName2) {
				sortVal = -1;
			} else {
				const machName1 = product1.machine_name.toLowerCase();
				const machName2 = product2.machine_name.toLowerCase();

				if (machName1 > machName2) {
					sortVal = 1;
				} else if (machName1 < machName2) {
					sortVal = -1;
				}
			}
		} else {
			if (product1 == null) {
				sortVal = -1;
			} else if (product2 == null) {
				sortVal = 1;
			}
		}

		return sortVal;
	}
}
