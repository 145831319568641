import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ProductUploadService } from "@app/Services/product-upload.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-product-add-step1",
	templateUrl: "./product-add-step1.component.html",
	styleUrls: ["./product-add-step1.component.css"]
})
export class ProductAddStep1Component implements OnInit {
	@Output() messageToEmit = new EventEmitter<File>();
	@Input() file: File ;
	dragDropfileType: string;
	dragDropMessage: string;
	
	getFileFromControl(inFile: File) {
		this.file = inFile;
		// emit to Main AddPage
		this.messageToEmit.emit(this.file);
	}

	constructor(
		private router: Router,
		private uploadUrlService: ProductUploadService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.dragDropfileType = "application/x-zip-compressed";
		this.dragDropMessage = this.translate.instant("DragNDropControl.DragnDropFile");
	}
}
