import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { Globals } from "@app/globals";
import { User } from "@app/Models/user";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(private http: HttpClient, private globals: Globals) {
		this.currentUserSubject = new BehaviorSubject<User>(this.globals.User);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(userid: string) {
		const u = new User();
		u.id = userid;

		this.globals.User = u;
		this.currentUserSubject.next(u);
	}

	logout() {
		// remove user from local storage to log user out
		this.globals.ClearUser();
		this.currentUserSubject.next(null);
	}
}
