import { Injectable } from "@angular/core";
import { User } from "./Models/user";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "./Models/Category";

@Injectable()
export class Globals {
	constructor(private translate: TranslateService) {
		this.platform[0].display_name = this.translate.instant("platform.mipro");
		this.platform[1].display_name = this.translate.instant(
			"platform.spectrumspatial"
		);
		this.platform[2].display_name = this.translate.instant("platform.mapxtreme");
	}

	set User(u: User) {
		localStorage.setItem(this.UserKey, JSON.stringify(u));
	}

	get User(): User {
		return JSON.parse(localStorage.getItem(this.UserKey));
	}

	set ShowHero(showHero: boolean) {
		sessionStorage.setItem(this.showHeroKey, showHero ? "True" : "False");
	}

	get ShowHero() {
		return sessionStorage.getItem(this.showHeroKey) === "False" ? false : true;
	}

	set Locale(loc: string) {
		sessionStorage.setItem(this.localeKey, loc);
		this.translate.use(loc);
	}

	get Locale() {
		return sessionStorage.getItem(this.localeKey);
	}

	set ProVersion(ver: string) {
		localStorage.setItem(this.proVersionKey, ver);
	}

	get ProVersion(): string {
		const prover: string = localStorage.getItem(this.proVersionKey);

		return prover ? prover : "all";
	}

	set MachineName(name: string) {
		localStorage.setItem(this.machineNameKey, name);
	}

	get MachineName(): string {
		return localStorage.getItem(this.machineNameKey);
	}
	MarketplaceClientProductId = "1";

	UserKey = "userid";
	showHeroKey = "showhero";
	localeKey = "locale";
	proVersionKey = "proVersion";
	machineNameKey = "machinename";
	appVersionPattern = "^[A-Za-z0-9]+(.[0-9]+)+$";
	descriptionCharLimit = 4000;
	productListCacheRefresh = false;
	productByVersionCacheRefresh = false;
	productCacheRefresh = false;
	showProgress = false;
	ErrorMessage = "";
	// platform list intitialised with english as default
	public platform: Platform[] = [
		{ id: "mipro", display_name: "MapInfo Pro" },
		{ id: "spectrumspatial", display_name: "Spectrum Spatial" },
		{ id: "mapxtreme", display_name: "MapXtreme" },
	];
	supportedLanguageCodes: string[] = [
		"en",
		"cs",
		"da",
		"de",
		"es",
		"fi",
		"fr",
		"he",
		"it",
		"ja",
		"nl",
		"pl",
		"pt",
		"ru",
		"sv",
		"tr",
		"zh-cn",
	];

	ClearUser() {
		localStorage.removeItem(this.UserKey);
	}

	IsUserLoggedIn() {
		return (
			this.User !== null &&
			this.User !== undefined &&
			this.User.id !== null &&
			this.User.id !== undefined &&
			this.User.id !== ""
		);
	}

	AddProVersionToUrl(urlValue: string): string {
		if (this.ProVersion) {
			urlValue = this.AddParamToUrl(
				urlValue,
				"product_pro_supported_version_from",
				this.ProVersion
			);
		}

		return urlValue;
	}

	AddLocaleParamToUrl(urlValue: string): string {
		if (this.Locale && this.Locale !== "en" && this.Locale !== "en-en") {
			urlValue = this.AddParamToUrl(urlValue, "locale_code", this.Locale);
		}

		return urlValue;
	}

	AddParamToUrl(
		urlValue: string,
		paramName: string,
		paramValue: string
	): string {
		if (!urlValue.includes("?")) {
			urlValue += "?";
		} else {
			urlValue += "&";
		}
		urlValue += paramName + "=" + paramValue;

		return urlValue;
	}

	WindowAlertErrorMessage(str) {
		if (!this.ErrorMessage.includes(str)) {
			this.ErrorMessage += str;
		}
		window.alert(this.ErrorMessage);
		// Reset to empty string value.
		this.ErrorMessage = "";
		this.showProgress = false;
	}

	HttpErrorResponseHandling(status: any) {
		let str = "";
		switch (status) {
			case 400:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_bad_request");
				break;
			case 401:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_unauthorized");
				break;
			case 402:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_payment_required");
				break;
			case 403:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_forbidden");
				break;
			case 404:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_resource_page_not_found");
				break;
			case 405:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_method_not_allowed");
				break;
			case 406:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_not_acceptable");
				break;
			case 407:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_authentication");
				break;
			case 408:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_timeout");
				break;
			case 409:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_conflict");
				break;
			case 410:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_gone");
				break;
			case 411:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_len_req");
				break;
			case 412:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_entity");
				break;
			case 413:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_uri_too_long");
				break;
			case 414:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_range");
				break;
			case 415:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_exp_failed") +
					" Unsupported Media Type.";
				break;
			case 416:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_internal_server_error") +
					" Requested Range Not Satisfiable.";
				break;
			case 417:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_method_not_supported");
				break;
			case 500:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_bad_gateway");
				break;
			case 501:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_service_unavialable");
				break;
			case 502:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_gateway_timeout");
				break;
			case 503:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_service_unavialable");
				break;
			case 504:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_gateway_timeout");
				break;
			case 505:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_http_version_not_supported");
				break;
			case 511:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_authentication_required");
				break;
			case 0:
				str +=
					this.translate.instant("global.error_status_code") +
					status +
					this.translate.instant("global.error_connection_sborted");
				break;
			default:
				str += this.translate.instant("global.error_status_code") + status;
				break;
		}
		if (!this.ErrorMessage.includes(str)) {
			this.ErrorMessage += str;
		}

		throw new Error(this.ErrorMessage);
	}

	getLanguageFromLocaleCode(locale: string): string {
		switch (locale) {
			case "en":
				locale = this.translate.instant("selectedLanguageName.en");
				break;
			case "cs":
				locale = this.translate.instant("selectedLanguageName.cs");
				break;
			case "da":
				locale = this.translate.instant("selectedLanguageName.da");
				break;
			case "de":
				locale = this.translate.instant("selectedLanguageName.de");
				break;
			case "es":
				locale = this.translate.instant("selectedLanguageName.es");
				break;
			case "fi":
				locale = this.translate.instant("selectedLanguageName.fi");
				break;
			case "fr":
				locale = this.translate.instant("selectedLanguageName.fr");
				break;
			case "he":
				locale = this.translate.instant("selectedLanguageName.he");
				break;
			case "it":
				locale = this.translate.instant("selectedLanguageName.it");
				break;
			case "ja":
				locale = this.translate.instant("selectedLanguageName.ja");
				break;
			case "nl":
				locale = this.translate.instant("selectedLanguageName.nl");
				break;
			case "pl":
				locale = this.translate.instant("selectedLanguageName.pl");
				break;
			case "pt":
				locale = this.translate.instant("selectedLanguageName.pt");
				break;
			case "ru":
				locale = this.translate.instant("selectedLanguageName.ru");
				break;
			case "sv":
				locale = this.translate.instant("selectedLanguageName.sv");
				break;
			case "tr":
				locale = this.translate.instant("selectedLanguageName.tr");
				break;
			case "zh-cn":
				locale = this.translate.instant("selectedLanguageName.zh-cn");
				break;
			default:
				break;
		}
		return locale;
	}
}
