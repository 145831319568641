import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-product-add-step3",
	templateUrl: "./product-add-step3.component.html",
	styleUrls: ["./product-add-step3.component.sass"]
})
export class ProductAddStep3Component implements OnInit {
	@Output() snapshotsToEmit = new EventEmitter();
	@Output() documentationMessageToEmit = new EventEmitter<File>();
	@Output() releaseNotesMessageToEmit = new EventEmitter<File>();
	@Output() legalTermMessageToEmit = new EventEmitter<File>();
	@Input() snapshotsFile: File[];
	@Input() documentationFile: File;
	@Input() releaseNotesFile: File;
	@Input() legalTermsFile: File;
	dragDropfileType: String;
	dragDropMessageDoc: String;
	dragDropMessageReleaseNotes: String;
	dragDropMessageLegal: String;
	dragDropFileTypeMessage: String;
	isEdit: boolean;
	constructor(private translate: TranslateService) { }
	ngOnInit() {
		this.dragDropfileType = "application/pdf";
		this.dragDropMessageDoc = this.translate.instant(
			"DragNDropControl.DragnDropDocumentation"
		);

		this.dragDropMessageReleaseNotes = this.translate.instant(
			"DragNDropControl.DragnDropReleaseNotes"
		);

		this.dragDropMessageLegal = this.translate.instant(
			"DragNDropControl.DragnDropLegalTerms"
		);

		this.dragDropFileTypeMessage = this.translate.instant(
			"DragNDropControl.fileTypePdf"
		);
		this.isEdit = false;

	}
	// directive
	getSnapshotsFileFromControl(inFile: File[]) {
		if (inFile != null) {
			this.snapshotsFile = inFile;
			// emit to Main AddPage
			this.snapshotsToEmit.emit(this.snapshotsFile);
		} else {
			this.snapshotsFile = null;
			// emit to Main AddPage
			this.snapshotsToEmit.emit(null);
		}
	}
	getDocumentationFileFromControl(inFile: File) {
		if (inFile != null) {
			this.documentationFile = inFile;
			// emit to Main AddPage
			this.documentationMessageToEmit.emit(this.documentationFile);
		} else {
			this.documentationFile = null;
			// emit to Main AddPage
			this.documentationMessageToEmit.emit(null);
		}
	}

	getReleaseNotesFileFromControl(inFile: File) {
		if (inFile != null) {
			this.releaseNotesFile = inFile;
			// emit to Main AddPage
			this.releaseNotesMessageToEmit.emit(this.releaseNotesFile);
		} else {
			this.releaseNotesFile = null;
			// emit to Main AddPage
			this.releaseNotesMessageToEmit.emit(null);
		}
	}

	getLegalTermsFileFromControl(inFile: File) {
		if (inFile != null) {
			this.legalTermsFile = inFile;
			this.legalTermMessageToEmit.emit(this.legalTermsFile);
		} else {
			this.legalTermsFile = null;
			// emit to Main AddPage
			this.legalTermMessageToEmit.emit(null);
		}
	}
}
