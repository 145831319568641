import {
	Component,
	OnInit,
	HostBinding,
	ViewChild,
	ElementRef,
} from "@angular/core";
import { Category, Platform } from "@app/Models/Category";
import { CategoryService } from "@app/Services/category.service";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { Globals } from "@app/globals";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit {
	@HostBinding("class.site-header")
	true: any;

	@ViewChild("searchLink", { static: false })
	searchLink: ElementRef;
	@ViewChild("searchInput", { static: false })
	searchInput: ElementRef;

	public environmentlabel: string = environment.EnvironmentLabel;

	public searchActive: boolean;

	categoryList: Category[];

	platform: Platform[];

	constructor(
		private categoryService: CategoryService,
		private router: Router,
		private globals: Globals
	) {
		this.searchActive = false;
		this.getCategorylist();
		this.platform = this.globals.platform;
	}

	IsUserLoggedIn() {
		return this.globals.IsUserLoggedIn();
	}

	ngOnInit() {}

	get showProgress() {
		return this.globals.showProgress;
	}

	async getCategorylist() {
		this.categoryList = (await this.categoryService.getCategoryList()).sort(
			function (catA, catB) {
				if (catA.category_name < catB.category_name) {
					return -1;
				}
				if (catA.category_name > catB.category_name) {
					return 1;
				}
				return 0;
			}
		);
	}

	OnSubmit(data: { searchtext: any }) {
		const searchTerms: string = data.searchtext;

		this.DoSearch(searchTerms, true);
	}

	performSearch(event: any) {
		const searchTerms: string = event.target.value;

		this.DoSearch(searchTerms, false);
	}

	DoSearch(searchTerms: string, fromForm: boolean) {
		searchTerms = searchTerms.trim();

		if (searchTerms !== "" && searchTerms != null) {
			if (fromForm) {
				this.toggleSearch();
			}
			this.router.navigate(["/searchresults"], {
				queryParams: { searchtext: searchTerms },
			});
		}
	}

	toggleSearch($event?: any) {
		if ($event != null && $event !== undefined) {
			$event.preventDefault();
		}

		if (this.searchActive) {
			this.searchActive = false;

			setTimeout(() => {
				this.searchLink.nativeElement.focus();
			}, 0);
		} else {
			this.searchActive = true;

			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			}, 0);
		}
	}
}
