import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProductItem, OtherLanguageInfo } from "@app/Models/ProductItem";
import { ProductUploadService } from "@app/Services/product-upload.service";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "@app/globals";
import { ProductListService } from "@app/Services/product-list.service";

@Component({
	selector: "app-product-add",
	templateUrl: "./product-add.component.html",
	styleUrls: ["./product-add.component.sass"],
})
export class ProductAddComponent implements OnInit {
	file: File = null;
	productItem: ProductItem = new ProductItem();
	productIconFile: File = null;
	otherLanguageInfo: Map<string, OtherLanguageInfo> = new Map<
		string,
		OtherLanguageInfo
	>();
	language: any = { str: "en" };
	step1: boolean;
	step2: boolean;
	step3: boolean;
	nextButtonEnabled: boolean;
	cancelButtonEnabled = true;
	backButtonEnabled = true;
	nextbuttonlabel;
	stepHeaderLabel: string;

	// step 3
	snapshotsFile: File[] = [];
	documentationFile: File = null;
	releaseNotesFile: File = null;
	legalTermsFile: File = null;
	uploadStatus = "";

	// input product id,
	inputProduct_id: string = null;
	inputProductVersion: string = null;
	inputProductName = "";
	// All version of product
	allVersionList: string[] = [];
	constructor(
		private router: Router,
		private uploadProductService: ProductUploadService,
		private translate: TranslateService,
		private globals: Globals,
		private route: ActivatedRoute,
		private productService: ProductListService
	) {
		this.SetNextButtonLabel("MainAddProduct.NextStep2");
	}

	SetNextButtonLabel(translateKey: string) {
		this.nextbuttonlabel = this.translate.instant(translateKey);
	}
	isAddNewVersionMode(): Boolean {
		let bAddNewVersionMode =
			this.inputProduct_id != null &&
			this.inputProduct_id !== undefined &&
			this.inputProductVersion !== null &&
			this.inputProductVersion !== undefined;
		if (bAddNewVersionMode) {
			if (
				this.inputProduct_id.trim() === "" ||
				this.inputProductVersion.trim() === ""
			) {
				bAddNewVersionMode = false;
			}
		}
		return bAddNewVersionMode;
	}
	ngOnInit() {
		this.inputProduct_id = this.route.snapshot.queryParamMap.get("id");
		this.inputProductVersion = this.route.snapshot.queryParamMap.get("version");
		this.productItem.product_publisher_user_id = "";
		this.productItem.product_short_description = "";
		this.productItem.product_long_description = "";
		this.productItem.product_name = "";
		this.productItem.whats_new = "";
		this.productItem.version = "";
		this.productItem.product_pro_supported_version_from = "";
		this.productItem.platform = "mipro";
		this.step1 = true;
		this.step2 = false;
		this.step3 = false;
		this.SetNextButtonLabel("MainAddProduct.NextStep2");
		this.nextButtonEnabled = false;
		// update product fields if in AddNewVersion mode
		this.updateProductFields();
		this.getAllVersions(this.inputProduct_id);
	}
	// This function is called in AddNewVersion mode.
	fetchOtherLangauageInfo(product_id: string, version: string) {
		this.globals.supportedLanguageCodes.forEach((element) => {
			this.productService
				.getProductByVersion(product_id, version, element, false)
				.then((values) => {
					if (values !== undefined && values != null && values[0] != null) {
						if (
							!(
								values[0].product_name.trim() === "" &&
								values[0].product_short_description.trim() === "" &&
								values[0].product_long_description.trim() === "" &&
								values[0].whats_new.trim() === ""
							)
						) {
							const languageInfo = new OtherLanguageInfo();
							languageInfo.product_name = values[0].product_name;
							languageInfo.language = element;
							languageInfo.product_long_description =
								values[0].product_long_description;
							languageInfo.product_short_description =
								values[0].product_short_description;
							languageInfo.whats_new = values[0].whats_new;
							this.otherLanguageInfo.set(element, languageInfo);
						}
					}
				});
		});
	}
	// This function is called in AddNewVersion mode.
	async updateProductFields() {
		try {
			if (this.isAddNewVersionMode()) {
				const locale = this.globals.Locale == null ? "en" : this.globals.Locale;
				await this.productService
					.getProductByVersion(
						this.inputProduct_id,
						this.inputProductVersion,
						locale,
						true
					)
					.then((values) => {
						if (values.length !== 0 && values[0] != null) {
							this.productItem.product_short_description =
								values[0].product_short_description === undefined ||
									values[0].product_short_description == null
									? ""
									: values[0].product_short_description;
							this.productItem.product_long_description =
								values[0].product_long_description === undefined ||
									values[0].product_long_description == null
									? ""
									: values[0].product_long_description;
							this.productItem.product_name =
								values[0].product_name === undefined || values[0].product_name == null
									? ""
									: values[0].product_name;
							this.productItem.product_pro_supported_version_from =
								values[0].product_pro_supported_version_from;
							this.productItem.whats_new =
								values[0].whats_new === undefined || values[0].whats_new == null
									? ""
									: values[0].whats_new;
							this.productItem.author = values[0].author;
							this.productItem.email = values[0].email;
							this.productItem.item_type_id = values[0].item_type_id;
							this.productItem.product_publisher_user_id =
								values[0].product_publisher_user_id;
							this.productItem.website = values[0].website;
							this.productItem.product_category_tags_id =
								values[0].product_category_tags_id;
							this.productItem.author = values[0].author;
							this.productItem.product_isfeatured = values[0].product_isfeatured;
							this.language.str = locale;
							this.inputProductName = values[0].product_name;
						}
					});
				// update otherLanguageInfo
				this.fetchOtherLangauageInfo(
					this.inputProduct_id,
					this.inputProductVersion
				);
			}
		} catch (error) {
			// skip catch
		}
	}
	// Get GUID for product_id
	CreateUUID() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	next() {
		if (this.step1 === true) {
			this.step2 = true;
			this.step1 = false;
			this.step3 = false;
			this.nextButtonEnabled = true;
			this.SetNextButtonLabel("MainAddProduct.NextStep3");
		} else if (this.step2 === true) {
			let isEnglishLangaugeProductNameEmpty = true;
			this.otherLanguageInfo.forEach((value) => {
				if (value.language.trim() === "en" && value.product_name.trim() !== "") {
					isEnglishLangaugeProductNameEmpty = false;
				}
			});
			if (isEnglishLangaugeProductNameEmpty) {
				this.globals.WindowAlertErrorMessage(
					this.translate.instant("MainAddProduct.ErrorEnglishProductNameEmpty")
				);
			} else {
				const appVerRegexp = new RegExp(this.globals.appVersionPattern);
				const regexpTestResult = appVerRegexp.test(this.productItem.version);
				if (
					this.productItem.product_name.trim() === "" ||
					this.productItem.version.trim() === "" ||
					this.productItem.product_pro_supported_version_from.trim() === "" ||
					this.language.str.trim() === "" ||
					this.isVersionExists() === true ||
					regexpTestResult === false
				) {
					if (regexpTestResult === false) {
						this.globals.WindowAlertErrorMessage(
							this.translate.instant("AddProductStep2.AppVersionRequired")
						);
					} else if (this.isVersionExists()) {
						this.globals.WindowAlertErrorMessage(
							this.translate.instant("MainAddProduct.ErrorVersionExist")
						);
					} else {
						this.globals.WindowAlertErrorMessage(
							this.translate.instant("MainAddProduct.ErrorFieldMissing")
						);
					}
				} else {
					this.step2 = false;
					this.step1 = false;
					this.step3 = true;
					this.SetNextButtonLabel("MainAddProduct.Finish");
				}
			}
		} else if (this.step3 === true) {
			try {
				this.nextButtonEnabled = false;
				this.cancelButtonEnabled = false;
				this.backButtonEnabled = false;
				let product_id = "";
				// Generate Id
				if (this.isAddNewVersionMode()) {
					product_id = this.inputProduct_id;
				} else {
					product_id = this.CreateUUID();
				}
				if (this.file == null) {
					throw this.translate.instant("MainAddProduct.ErrorNoFileAttached");
				}
				const appVerRegexp = new RegExp(this.globals.appVersionPattern);
				if (
					this.productItem.product_name.trim() === "" ||
					this.productItem.version.trim() === "" ||
					this.productItem.product_pro_supported_version_from.trim() === "" ||
					this.language.str.trim() === "" ||
					!appVerRegexp.test(this.productItem.version)
				) {
					throw this.translate.instant("MainAddProduct.ErrorFieldMissing");
				} else {
					let errorMessage = "";
					this.uploadStatus = this.translate.instant(
						"MainAddProduct.Uploading_productFile"
					);
					this.globals.showProgress = true;
					// upload product in S3
					this.uploadProductService
						.uploadFile(
							this.file,
							this.file.name,
							product_id,
							this.productItem.version
						)
						.then(
							() => {
								// upload product icon in s3
								this.uploadProductService
									.uploadFile(
										this.productIconFile,
										"icon.png",
										product_id,
										this.productItem.version
									)
									.then(
										() => {
											this.uploadStatus = this.translate.instant(
												"MainAddProduct.Uploading_snapshots"
											);
											if (this.snapshotsFile != null) {
												// upload Snapshots  in s3
												for (let index = 0; index < this.snapshotsFile.length; index++) {
													const file = this.snapshotsFile[index];
													const name = "snapshots/snapshot" + index + ".png";
													this.uploadProductService
														.uploadFile(file, name, product_id, this.productItem.version)
														.then(
															() => { },
															() => {
																errorMessage += this.translate.instant(
																	"MainAddProduct.ErrorIconUpload"
																);

																this.globals.WindowAlertErrorMessage(errorMessage);
															}
														);
												}
											}
										},
										() => {
											errorMessage += this.translate.instant(
												"MainAddProduct.ErrorIconUpload"
											);
											this.globals.WindowAlertErrorMessage(errorMessage);
										}
									)
									.then(() => {
										this.uploadStatus = this.translate.instant(
											"MainAddProduct.Uploading_documentation"
										);
										if (this.documentationFile != null) {
											// upload Documentation  in s3
											this.uploadProductService.uploadFile(
												this.documentationFile,
												"docs/documentation.pdf",
												product_id,
												this.productItem.version
											);
										}
									})
									.then(
										() => {
											this.uploadStatus = this.translate.instant(
												"MainAddProduct.Uploading_releaseNotes"
											);
											if (this.releaseNotesFile != null) {
												// upload release notes in s3
												this.uploadProductService.uploadFile(
													this.releaseNotesFile,
													"docs/release_notes.pdf",
													product_id,
													this.productItem.version
												);
											}
										},
										() => {
											errorMessage += this.translate.instant(
												"MainAddProduct.ErrorReleaseNotes"
											);
											this.globals.WindowAlertErrorMessage(errorMessage);
										}
									)
									.then(
										() => {
											this.uploadStatus = this.translate.instant(
												"MainAddProduct.Uploading_legalDocs"
											);
											if (this.legalTermsFile != null) {
												// upload legal terms in s3
												this.uploadProductService.uploadFile(
													this.releaseNotesFile,
													"docs/legal.pdf",
													product_id,
													this.productItem.version
												);
											}
										},
										() => {
											errorMessage += this.uploadStatus = this.translate.instant(
												"MainAddProduct.ErrorLegalFile"
											);
											this.globals.WindowAlertErrorMessage(errorMessage);
										}
									)
									.then(
										() => {
											this.uploadStatus = this.translate.instant(
												"MainAddProduct.Uploading_productMetadata"
											);
											// add metadata in dynamodb tables
											this.uploadProductService
												.postProductMetadata(
													this.productItem,
													product_id,
													this.language.str,
													this.otherLanguageInfo
												)
												.then(() => {
													this.globals.showProgress = false;
													this.finished();
												});
										},
										() => {
											errorMessage += this.translate.instant(
												"MainAddProduct.ErrorMetadata"
											);
											this.globals.WindowAlertErrorMessage(errorMessage);
										}
									);
							},
							() => {
								errorMessage += this.translate.instant(
									"MainAddProduct.ErrorProductUpload"
								);
								this.globals.WindowAlertErrorMessage(errorMessage);
								// Reset state of Buttons and uploadStatus value.
								this.nextButtonEnabled = true;
								this.cancelButtonEnabled = true;
								this.backButtonEnabled = true;
								this.uploadStatus = "";
							}
						);
				}
			} catch (error) {
				this.globals.showProgress = false;
				this.globals.WindowAlertErrorMessage(error);
			}
		}
	}
	finished() {
		if (this.isAddNewVersionMode()) {
			this.router.navigate(["/product", this.inputProduct_id]);
		} else {
			this.router.navigate([""]);
		}
	}
	back() {
		if (this.step2 === true) {
			this.step1 = true;
			this.step2 = false;
			this.step3 = false;
			this.SetNextButtonLabel("MainAddProduct.NextStep2");
		}
		if (this.step3 === true) {
			this.step2 = true;
			this.step1 = false;
			this.step3 = false;
			this.SetNextButtonLabel("MainAddProduct.NextStep3");
		}
	}

	cancel() {
		this.finished();
	}
	// Function is used in add new version mode.
	isVersionExists(): boolean {
		if (!this.isAddNewVersionMode()) {
			return false;
		}

		if (this.allVersionList == null || this.allVersionList === undefined) {
			return false;
		}
		let bRet = false;
		this.allVersionList.forEach((version) => {
			if (version.trim() === this.productItem.version.trim()) {
				bRet = true;
			}
		});
		return bRet;
	}
	async getAllVersions(product_id: string) {
		if (product_id != null && product_id !== undefined) {
			await this.productService.getAllVersionProducts(product_id).then((value) => {
				value.forEach((element) => {
					this.allVersionList.push(element.version.trim());
				});
			});
		}
	}
	getProductIconFile(inFile: File) {
		this.productIconFile = inFile;
	}
	getSnapshotsFile(inFile: File[]) {
		this.snapshotsFile = inFile;
	}
	getDocumentationFile(inFile: File) {
		this.documentationFile = inFile;
	}
	getReleaseNotesile(inFile: File) {
		this.releaseNotesFile = inFile;
	}
	getLegalTermFile(inFile: File) {
		this.legalTermsFile = inFile;
	}

	getFile(inFile: File) {
		this.file = inFile;
		this.nextButtonEnabled = this.file != null;
	}
}
