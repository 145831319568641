import { Component, OnInit } from "@angular/core";
import { Globals } from "@app/globals";
import { PromoHeroService } from "@app/Services/promo-hero.service";
import { PromoHero } from "@app/Models/PromoHero";

@Component({
	selector: "app-promo-hero",
	templateUrl: "./promo-hero.component.html",
	styleUrls: ["./promo-hero.component.sass"]
})
export class PromoHeroComponent implements OnInit {
	showHero = false;
	heroContent: PromoHero = new PromoHero();

	constructor(private globals: Globals, private promoService: PromoHeroService) {
		this.heroContent.title = "";
		this.heroContent.promo_hero_title = "";
		this.heroContent.read_more_text = "";
		this.heroContent.backgorund_url = "/assets/hero_bg2.png";
	}

	ngOnInit() {
		this.getPromoHeroInfo();
	}

	async getPromoHeroInfo() {
		const temp = await this.promoService.getPromoInfo();

		if (temp[0] != null && temp[0].promo_hero_title != null) {
			this.heroContent = temp[0];
			this.showHero = this.globals.ShowHero;
		} else {
			this.showHero = false;
		}
	}

	remindMe = $event => {
		$event.preventDefault();
		// set hero to display again next time, but hide it for now
		this.showHero = false;
	};

	gotIt = () => {
		this.globals.ShowHero = this.showHero = false;
	};
}
