import { Injectable } from "@angular/core";
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from "@angular/router";

import { environment } from "../../environments/environment";

import { AuthenticationService } from "@app/Services/authentication.service";
import { Globals } from "@app/globals";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private globals: Globals
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let locale = route.queryParamMap.get(this.globals.localeKey);
		if (locale) {
			this.globals.Locale = locale;
		} else {
			// This looks really dumb but we are doing this so that
			// the translation library gets set when the page is refreshed.
			locale = this.globals.Locale;
			if (locale !== null && locale !== undefined) {
				this.globals.Locale = locale;
			}
		}

		const mname = route.queryParamMap.get(this.globals.machineNameKey);
		if (mname) {
			this.globals.MachineName = mname;
		}

		let proVersion = route.queryParamMap.get(
			this.globals.proVersionKey.toLowerCase()
		);

		if (proVersion == null || proVersion === undefined) {
			proVersion = route.queryParamMap.get(this.globals.proVersionKey);
		}

		if (proVersion) {
			this.globals.ProVersion = proVersion;
		}

		const userid = route.queryParamMap.get(this.globals.UserKey);
		if (userid) {
			this.authenticationService.login(userid);
		}

		const currentUser = this.authenticationService.currentUserValue;
		const expectedRole = route.data.expectedRole;

		if (
			currentUser == null &&
			expectedRole !== null &&
			expectedRole !== undefined
		) {
			this.router.navigate(["/"], {});
			return false;
		} else if (
			!environment.AdminAvailable &&
			(route.routeConfig.path === "editproduct" ||
				route.routeConfig.path === "addproduct" ||
				route.routeConfig.path === "admin")
		) {
			this.router.navigate(["/"], {});
			return false;
		} else {
			return true;
		}

		// not logged in so redirect to login page with the return url
		// this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
		// return false;
	}
}
