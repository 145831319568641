export class PromoHero {
	productVersion: string;
	read_more_text: string;
	locale: string;
	product_id: string;
	promo_hero_title: string;
	title: string;
	backgorund_url: string;
	get showReadMore(): boolean {
		return (
			this.read_more_text !== undefined &&
			this.read_more_text !== "" &&
			this.read_more_text != null
		);
	}
}
