import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "@app/globals";

export interface FileHandle {
  file: File,
  url: SafeUrl
}

@Directive({
  selector: '[appFilesDragDrop]'
})
export class FilesDragDropDirective {

  @Output('droppedService') droppedService: EventEmitter<FileHandle[]> = new EventEmitter();
  @HostBinding("style.background") private background = "#eee";
  files: FileHandle[] = [];
  constructor(private translate: TranslateService, private sanitizer: DomSanitizer, private globals: Globals) { }

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#999";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#eee";
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    this.files = [];
    if (evt.dataTransfer.files.length > 4) {
      this.globals.WindowAlertErrorMessage(this.translate.instant("FilesUploadPreview.MaxScreenshots"));
    }
    else {
      for (let index = 0; index < evt.dataTransfer.files.length; index++) {
        let file: File = evt.dataTransfer.files[index];
        if ("image/png" == file.type) {
          const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
          this.files.push({ file, url });
        }
        else {
          this.globals.WindowAlertErrorMessage(this.translate.instant("FilesUploadPreview.InvalidFileWarning"));
        }
      }

      if (this.files.length > 0) {
        this.droppedService.emit(this.files);
      }
    }
  }

}
