export const environment = {
	production: false,
	log: true,
	ApiVersion: "/v1",
	ProductListEndpoint: "/product",
	CategoryListEndpoint: "/category",
	ProductUrlEndpoint: "/downloadurl",
	ProductSearchEndpoint: "/fuzzysearch",
	PromoHeroEndpoint: "/promohero",
	ClientInstallInfoEndpoint: "/clientproductinfo",
	UserTransactionEndpoint: "/transaction",
	MiproVersionEndPoint: "/mapinfoversions",
	ProductUploadUrlEndPoint: "/uploadurl",
	DeleteProduct: "/deleteproduct",
	flags: {
		useNewHeader: true
	}
};
