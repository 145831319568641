import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HeaderComponent } from "@app/header/header.component";
import { FooterComponent } from "@app/footer/footer.component";
import { ProductFieldComponent } from "@app/product-field/product-field.component";
import { ProductComponent } from "./product/product.component";
import { MyproductsComponent } from "./myproducts/myproducts.component";
import { SearchResultsComponent } from "@app/search-results/search-results.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { LoginComponent } from "./login/login.component";
import { InstallComponent } from "./install/install.component";
import { AdminHomePageComponent } from "./admin/admin-home-page/admin-home-page.component";
import { ProductAddComponent } from "@app/product-add/product-add.component";
import { ProductAddStep1Component } from "@app/product-add/product-add-step1/product-add-step1.component";
import { ProductAddStep2Component } from "@app/product-add/product-add-step2/product-add-step2.component";
import { ProductAddStep3Component } from "@app/product-add/product-add-step3/product-add-step3.component";
import { ProductEditComponent } from "@app/product-edit/product-edit.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "",
				outlet: "header",
				component: HeaderComponent,
			},
			{
				path: "",
				component: ProductFieldComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "category/:categoryid",
				component: ProductFieldComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "platform/:platformid",
				component: ProductFieldComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "",
				outlet: "footer",
				component: FooterComponent,
			},
			{
				path: "product/:id",
				component: ProductComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "myproducts",
				component: MyproductsComponent,
				canActivate: [AuthGuard],
				data: {
					expectedRole: "authenticatedUser",
				},
			},
			{
				path: "searchresults",
				component: SearchResultsComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "install/:id",
				component: InstallComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "addproduct",
				component: ProductAddComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "editproduct",
				component: ProductEditComponent,
				canActivate: [AuthGuard],
			},
			{
				path: "404",
				component: PageNotFoundComponent,
			},
			{
				path: "login",
				component: LoginComponent,
			},
			{
				path: "**",
				redirectTo: "",
			},
			{
				path: "admin",
				component: AdminHomePageComponent,
				canActivate: [AuthGuard],
				data: {
					expectedRole: "admin",
				},
			},
		],
	},
	{
		path: "",
		redirectTo: "",
		pathMatch: "full",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
