import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
	ɵChangeDetectorStatus,
} from "@angular/core";
import { ProductItem } from "@app/Models/ProductItem";
import { ProductDownloadService } from "@app/Services/product-download.service";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "app-product-group",
	templateUrl: "./product-group.component.html",
	styleUrls: ["./product-group.component.sass"],
})
export class ProductGroupComponent implements OnInit {
	@Input("sectionTitle") title: string;
	@Input("overlayPrefix") overlayPrefix: string;
	@Input("productItems") products: ProductItem[];

	@Input("isLoading") loading: boolean = true;

	constructor(
		private downloadUrlService: ProductDownloadService,
		private http: HttpClient
	) {}

	ngOnInit() {}

	on(div: string) {
		this.off();
		document.getElementById(div).style.display = "block";
	}

	off() {
		const list = document.getElementsByClassName("overlay");
		for (let i = 0; i < list.length; i++) {
			const o = list[i];
			(o as HTMLElement).style.display = "none";
		}
		// document.getElementById(div).style.display = "none";
	}

	async getProduct(product_id: string) {
		const url = await this.downloadUrlService.getProductDownloadurl(product_id);
		this.http.get(url, { responseType: "blob" });

		const link = document.createElement("a");
		link.href = url;
		link.download = "product.zip";
		link.dispatchEvent(new MouseEvent("click"));
	}
}
