import { Injectable } from "@angular/core";
import { HttpClient,HttpErrorResponse } from "@angular/common/http";
import { Globals } from "@app/globals";
import {
	UserTransaction,
	UserProductHistory,
} from "@app/Models/UserTransaction";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { ProductListService } from "./product-list.service";
import { ProductItem } from "@app/Models/ProductItem";

@Injectable({
	providedIn: "root",
})
export class UserTransactionServiceService {
	constructor(
		private http: HttpClient,
		private globals: Globals,
		private productService: ProductListService
	) {}

	async GetUserTransactionWithProductInfo(
		userid: string
	): Promise<UserProductHistory[]> {
		const productHistory: UserProductHistory[] = [];

		const userRecords = await this.GetUserTransactionRecords(userid);

		if (
			userRecords !== undefined &&
			userRecords !== null &&
			userRecords.length > 0
		) {
			const productIdCollections: string[] = userRecords.map((x) => x.product_id);

			const productIdString: string = productIdCollections.join(",");

			const productInfo: ProductItem[] = await this.productService.getProducts(
				productIdString,
				"all",
				true,
				true
			);

			await this.asyncForEach(userRecords, async (record: UserTransaction) => {
				if (this.globals.MarketplaceClientProductId !== record.product_id) {
					const prodHistory = new UserProductHistory();

					prodHistory.productInfo = productInfo.find(
						(x) => x.product_id === record.product_id
					);

					prodHistory.transaction = record;

					if (
						prodHistory.productInfo === undefined ||
						prodHistory.productInfo === null
					) {
						prodHistory.productInfo = new ProductItem();
						prodHistory.productInfo.product_id = record.product_id;
						prodHistory.productInfo.version = record.version;
					}

					productHistory.push(prodHistory);
				}
			});
		}

		return productHistory;
	}

	async GetUserTransactionRecords(userid: string): Promise<UserTransaction[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.UserTransactionEndpoint;
		if (userid != null && userid !== undefined) {
			urlValue = this.globals.AddParamToUrl(urlValue, "subscriber_id", userid);
		}

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);

		let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
			(err: HttpErrorResponse) => {
			  this.globals.HttpErrorResponseHandling(err.status);
		  });
		return JSON.parse(x.body);
	}

	async asyncForEach(array, callback) {
		if (array != null) {
			for (let index = 0; index < array.length; index++) {
				await callback(array[index], index, array);
			}
		}
	}
}
