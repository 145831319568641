<!-- Snapshots -->
<div class="row mt-4">
	<span
		><label class="ml-3" translate>AddProductStep3.ScreenshotHeading1</label>
		<label
			style="font-style: italic; margin-left: 2px; color: darkgray"
			translate
		>
			AddProductStep3.ScreenshotHeading2</label
		></span
	>

	<div class="col-md-12">
		<app-files-upload-preview
			[files]="snapshotsFile"
			[isEdit]="isEdit"
			(messageToEmit)="getSnapshotsFileFromControl($event)"
		>
		</app-files-upload-preview>
	</div>
</div>
<!-- Documentation -->
<div class="row mt-4">
	<label class="ml-3" translate>AddProductStep3.Documentation</label>
	<div class="col-md-12">
		<app-drag-drop-file-control
			[dragDropMessage]="dragDropMessageDoc"
			[dragDropFileTypeMessage]="dragDropFileTypeMessage"
			[fileType]="dragDropfileType"
			[file]="documentationFile"
			[thumbnailSize]="false"
			(messageToEmit)="getDocumentationFileFromControl($event)"
		></app-drag-drop-file-control>
	</div>
</div>

<!-- Release Notes -->
<div class="row mt-4">
	<label class="ml-3" translate>AddProductStep3.ReleaseNotes</label>
	<div class="col-md-12">
		<app-drag-drop-file-control
			[dragDropMessage]="dragDropMessageReleaseNotes"
			[dragDropFileTypeMessage]="dragDropFileTypeMessage"
			[fileType]="dragDropfileType"
			[file]="releaseNotesFile"
			[thumbnailSize]="false"
			(messageToEmit)="getReleaseNotesFileFromControl($event)"
		></app-drag-drop-file-control>
	</div>
</div>

<!-- Legal Terms -->
<div class="row mt-4 mb-4">
	<label class="ml-3" translate>AddProductStep3.LegalTerms</label>
	<div class="col-md-12">
		<app-drag-drop-file-control
			[file]="legalTermsFile"
			[dragDropMessage]="dragDropMessageLegal"
			[dragDropFileTypeMessage]="dragDropFileTypeMessage"
			[fileType]="dragDropfileType"
			[thumbnailSize]="false"
			(messageToEmit)="getLegalTermsFileFromControl($event)"
		>
		</app-drag-drop-file-control>
	</div>
</div>
