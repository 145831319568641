import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { AuthenticationService } from "@app/Services/authentication.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.sass"]
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;
	error = "";

	constructor(
		// private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit() {
		// this.loginForm = this.formBuilder.group({
		//   username: ['', Validators.required]
		// });

		// reset login status
		// this.authenticationService.logout();

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
	}

	onSubmit(data: { username: any }) {
		this.submitted = true;

		// stop here if form is invalid
		// if (this.loginForm.invalid) {
		//   return;
		// }

		this.loading = true;
		this.authenticationService.login(data.username);
		this.router.navigate([this.returnUrl]);
	}
}
