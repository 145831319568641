<div class="page-header">
	<div class="container">
		<div class="row">
			<div class="col">
				<nav aria-label="breadcrumb" class="breadcrumbs">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a [routerLink]="['/']" translate>product_page.home</a>
						</li>
						<li class="breadcrumb-item">
							<a [routerLink]="['/']" translate>product_page.products_page</a>
						</li>
						<li class="breadcrumb-item active" *ngIf="isAddNewVersionMode()">
							<a [routerLink]="['/product/', inputProduct_id]">{{
								productItem.product_name
							}}</a>
						</li>
						<li
							class="breadcrumb-item active"
							aria-current="page"
							translate
							*ngIf="isAddNewVersionMode()"
						>
							MainAddProduct.BreadCrumbLabel
						</li>
						<li
							class="breadcrumb-item active"
							aria-current="page"
							translate
							*ngIf="!isAddNewVersionMode()"
						>
							MainAddProduct.BreadCrumbNewProduct
						</li>
					</ol>
				</nav>
			</div>
		</div>
	</div>
</div>
<div class="container mb-5">
	<div class="row align-items-center">
		<div class="col">
			<h1 translate *ngIf="step1">AddProductStep1.header</h1>
			<h1 translate *ngIf="step2">AddProductStep2.header</h1>
			<h1 translate *ngIf="step3">AddProductStep3.Header</h1>
		</div>
		<div class="col text-right">
			<button
				class="btn btn-secondary"
				style="vertical-align: bottom"
				type="button"
				(click)="cancel()"
				[disabled]="!cancelButtonEnabled"
				translate
				matRipple
			>
				MainAddProduct.Cancel
			</button>
			<button
				*ngIf="!step1"
				class="btn btn-secondary"
				type="button"
				(click)="back()"
				[disabled]="!backButtonEnabled"
				translate
				matRipple
			>
				MainAddProduct.Back
			</button>
			<button
				class="btn btn-primary"
				type="button"
				(click)="next()"
				[disabled]="!nextButtonEnabled"
				translate
				matRipple
			>
				{{ nextbuttonlabel }}
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<label style="margin-left: 5px; color: blue">{{ uploadStatus }}</label>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div *ngIf="step1">
				<app-product-add-step1
					[file]="file"
					(messageToEmit)="getFile($event)"
				></app-product-add-step1>
			</div>
			<div *ngIf="step2">
				<app-product-add-step2
					[productIconFile]="productIconFile"
					[productItem]="productItem"
					[otherLanguageInfo]="otherLanguageInfo"
					[language]="language"
					(messageToEmit)="getProductIconFile($event)"
				></app-product-add-step2>
			</div>
			<div *ngIf="step3">
				<app-product-add-step3
					[snapshotsFile]="snapshotsFile"
					[documentationFile]="documentationFile"
					[releaseNotesFile]="releaseNotesFile"
					[legalTermsFile]="legalTermsFile"
					(snapshotsToEmit)="getSnapshotsFile($event)"
					(documentationMessageToEmit)="getDocumentationFile($event)"
					(releaseNotesMessageToEmit)="getReleaseNotesile($event)"
					(legalTermMessageToEmit)="getLegalTermFile($event)"
				></app-product-add-step3>
			</div>
		</div>
	</div>
</div>
