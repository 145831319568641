<div class="row">
	<h2>{{ title }}</h2>
</div>
<div class="row" *ngIf="!loading && products.length === 0">
	<div class="col" translate>products.no_products</div>
</div>
<div class="row" *ngIf="loading">
	<div class="col" translate>products.products_loading</div>
</div>
<div class="row">
	<div
		class="card card-shadowed"
		*ngFor="let p of products; let i = index"
		(mouseenter)="on('overlay' + overlayPrefix + i)"
		(mouseover)="on('overlay' + overlayPrefix + i)"
	>
		<a href="#" [routerLink]="['/product/', p.product_id]">
			<div class="card-body text-center p-3">
				<img
					class="img-fluid mb-2"
					src="{{ p.icon_url }}"
					alt="{{ p.product_name }}"
					style="height: 96px; width: 96px"
				/>
				<h4 class="card-title text-wrap">{{ p.product_name }}</h4>
			</div>
		</a>
		<div
			class="overlay rounded"
			id="overlay{{ overlayPrefix + i }}"
			(mouseleave)="off()"
		>
			<div class="row h-75">
				<div class="col">
					<a
						href="#"
						[routerLink]="['/product/', p.product_id]"
						class="overlay-text"
					>
						<div class="overlay-title align-top h-25">{{ p.product_name }}</div>
						<div class="overlay-text h-75">{{ p.product_short_description }}</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
