import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";

import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { Globals } from "./globals";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ProductFieldComponent } from "./product-field/product-field.component";
import { PromoHeroComponent } from "./promo-hero/promo-hero.component";
import { ProductComponent } from "./product/product.component";
import { MyproductsComponent } from "./myproducts/myproducts.component";
import { SearchResultsComponent } from "./search-results/search-results.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ProductGroupComponent } from "./product-group/product-group.component";
import { LoginComponent } from "./login/login.component";
import { HeaderShadowDirective } from "./Directives/HeaderShadow.Directive";
import { InstallComponent } from "./install/install.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { MatNativeDateModule, MatRipple } from "@angular/material/core";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AdminHomePageComponent } from "./admin/admin-home-page/admin-home-page.component";
import { ProductAddComponent } from "./product-add/product-add.component";
import { ProductAddStep1Component } from "./product-add/product-add-step1/product-add-step1.component";
import { ProductAddStep2Component } from "./product-add/product-add-step2/product-add-step2.component";
import { ProductAddStep3Component } from "./product-add/product-add-step3/product-add-step3.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { DragDropFileControlComponent } from "./drag-drop-file-control/drag-drop-file-control.component";
import { ProductEditComponent } from "./product-edit/product-edit.component";
import { FilesUploadPreviewComponent } from "./files-upload-preview/files-upload-preview.component";
import { FilesDragDropDirective } from "./Directives/files-drag-drop.directive";
import { WebpackTranslateLoader } from "./WebpackTranslationLoader";
import { MatSelectModule } from "@angular/material/select";
import { TableModule } from "primeng-lts/table";

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		ProductFieldComponent,
		PromoHeroComponent,
		ProductComponent,
		MyproductsComponent,
		SearchResultsComponent,
		PageNotFoundComponent,
		ProductGroupComponent,
		LoginComponent,
		HeaderShadowDirective,
		InstallComponent,
		AdminHomePageComponent,
		ProductAddComponent,
		ProductAddStep1Component,
		ProductAddStep2Component,
		ProductAddStep3Component,
		DragDropFileControlComponent,
		FilesUploadPreviewComponent,
		FilesDragDropDirective,
		ProductEditComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		RouterModule,
		FormsModule,
		MatNativeDateModule,
		MatTableModule,
		MatSortModule,
		MatExpansionModule,
		MatIconModule,
		MatProgressBarModule,
		MatRippleModule,
		NgbModule,
		NgSelectModule,
		NgOptionHighlightModule,
		ToastrModule.forRoot(),
		BrowserAnimationsModule,
		MatSelectModule,
		TableModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	exports: [RouterModule, MatSortModule, MatTableModule, MatExpansionModule],
	providers: [Globals],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
	// return new TranslateHttpLoader(http);
	return new WebpackTranslateLoader();
}
