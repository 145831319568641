<header>
    <!-- Include your header component here -->
    <router-outlet name="header"></router-outlet>
  </header>
  
  <main class="site-content">
    <router-outlet class="site-content"></router-outlet>

</main>
  
<app-footer class="mt-auto">
<footer>
    <router-outlet name="footer"></router-outlet>
</footer>
</app-footer>