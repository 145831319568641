import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ProductItem } from "@app/Models/ProductItem";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { Globals } from "@app/globals";

@Injectable({
	providedIn: "root",
})

export class ProductListService {
	constructor(private http: HttpClient, private globals: Globals) {}

	async getProductList(categoryId: string): Promise<ProductItem[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.ProductListEndpoint;
		if (categoryId != null && categoryId !== undefined) {
			urlValue = this.globals.AddParamToUrl(
				urlValue,
				"Product_Category_Tags_Id",
				categoryId
			);
		}

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);
		urlValue = this.globals.AddProVersionToUrl(urlValue);
		urlValue = this.globals.AddParamToUrl(urlValue, "return_latest", "1");
		let x;
		if (this.globals.productListCacheRefresh) {
			x = await this.http.get<ApiResponse>(urlValue,
				{ headers: { "Cache-Control": "no-cache",
					"Pragma": "no-cache",
					"Expires": "Sat, 01 Jan 2000 00:00:00 GMT"}}).toPromise().catch(
						(err: HttpErrorResponse) => {
							this.globals.HttpErrorResponseHandling(err.status);
						});
			this.globals.productListCacheRefresh = false;

		} else {
			x = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
				(err: HttpErrorResponse) => {
					this.globals.HttpErrorResponseHandling(err.status);
				});
		}
		return JSON.parse(x.body);
	}

	async getProductByVersion(
		ProductId: string,
		version: string,
		language: string,
		fallback_locale_to_english: boolean
	): Promise<ProductItem[]> {
		if (
			ProductId != null &&
			ProductId !== undefined &&
			version != null &&
			language != null
		) {
			let urlValue =
				environment.ApiUrl +
				environment.ApiVersion +
				environment.ProductListEndpoint;

			urlValue = this.globals.AddParamToUrl(urlValue, "Product_Id", ProductId);
			urlValue = this.globals.AddParamToUrl(urlValue, "version", version);
			urlValue = this.globals.AddParamToUrl(urlValue, "locale_code", language);
			if (fallback_locale_to_english) {
				urlValue = this.globals.AddParamToUrl(urlValue, "fallback_locale_to_english", "true");
			} else {
				urlValue = this.globals.AddParamToUrl(urlValue, "fallback_locale_to_english", "false");
			}
			urlValue = this.globals.AddParamToUrl(
				urlValue,
				"product_pro_supported_version_from",
				"all"
			);
			let x;
			if (this.globals.productByVersionCacheRefresh) {
				x = await this.http.get<ApiResponse>(urlValue,
					{ headers: { "Cache-Control": "no-cache",
					"Pragma": "no-cache",
					"Expires": "Sat, 01 Jan 2000 00:00:00 GMT"}}).toPromise().catch(
						(err: HttpErrorResponse) => {
							this.globals.HttpErrorResponseHandling(err.status);
						});
					this.globals.productByVersionCacheRefresh = false;
			} else {
				x = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
					(err: HttpErrorResponse) => {
						this.globals.HttpErrorResponseHandling(err.status);
					});
			}
			const y = JSON.parse(x.body);
			return y;
		} else {
			return null;
		}
	}

	async getProductsByPlatform(selectedPatform: string, categoryId: string): Promise<ProductItem[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.ProductListEndpoint;
		if (categoryId != null && categoryId !== undefined) {
			urlValue = this.globals.AddParamToUrl(
				urlValue,
				"Product_Category_Tags_Id",
				categoryId
			);
		}

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);
		urlValue = this.globals.AddProVersionToUrl(urlValue);
		urlValue = this.globals.AddParamToUrl(urlValue, "return_latest", "1");
		let x;
		if (this.globals.productListCacheRefresh) {
			x = await this.http.get<ApiResponse>(urlValue,
				{ headers: { "Cache-Control": "no-cache",
					"Pragma": "no-cache",
					"Expires": "Sat, 01 Jan 2000 00:00:00 GMT"}}).toPromise().catch(
						(err: HttpErrorResponse) => {
							this.globals.HttpErrorResponseHandling(err.status);
						});
			this.globals.productListCacheRefresh = false;

		} else {
			x = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
				(err: HttpErrorResponse) => {
					this.globals.HttpErrorResponseHandling(err.status);
				});
		}

		const results: ProductItem[] = JSON.parse(x.body).filter(product => product.platform !== undefined);

		return results.filter(y => y.platform === selectedPatform);
	}

	async getProduct(ProductId: string, proVersion?: string) {
		const results: ProductItem[] = await this.getProducts(ProductId, proVersion);

		return results[0];
	}
	// return all versions of product
	async getAllVersionProducts(productId: string) {
		return await this.getProducts(productId, null, false);
	}

	async getProducts(
		ProductId: string,
		proVersion?: string,
		return_latest: boolean = true,
		include_deleted: boolean = false
	): Promise<ProductItem[]> {
		if (ProductId != null && ProductId !== undefined) {
			let urlValue =
				environment.ApiUrl +
				environment.ApiVersion +
				environment.ProductListEndpoint;

			urlValue = this.globals.AddParamToUrl(urlValue, "Product_Id", ProductId);
			if (proVersion !== undefined && proVersion !== null && proVersion !== "") {
				urlValue = this.globals.AddParamToUrl(
					urlValue,
					"product_pro_supported_version_from",
					proVersion
				);
			} else {
				urlValue = this.globals.AddProVersionToUrl(urlValue);
			}
			urlValue = this.globals.AddLocaleParamToUrl(urlValue);
			if (return_latest) {
				urlValue = this.globals.AddParamToUrl(urlValue, "return_latest", "1");
			}
			if (include_deleted) {
				urlValue = this.globals.AddParamToUrl(urlValue, "include_deleted", "true");
			}
			let x;
			if ( this.globals.productCacheRefresh ) {
				x = await this.http.get<ApiResponse>(urlValue, { headers: { "Cache-Control": "no-cache",
				"Pragma": "no-cache",
				"Expires": "Sat, 01 Jan 2000 00:00:00 GMT"}}).toPromise().catch(
					(err: HttpErrorResponse) => {
						this.globals.HttpErrorResponseHandling(err.status);
					});
				this.globals.productCacheRefresh = false;
			} else {
				x = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
					(err: HttpErrorResponse) => {
						this.globals.HttpErrorResponseHandling(err.status);
					});

			}
			const y = JSON.parse(x.body);
			return y;
		} else {
			return null;
		}
	}
}
