<div class="container" style="padding-bottom: 5em">
	<div class="main-header">
		<h1 translate>my_products.title</h1>
	</div>
	<div *ngIf="areThereUpdates()">
		<div>
			<h2 translate>my_products.updates_title</h2>
		</div>
		<div style="text-align: right" *ngIf="showUpdateAllButton()">
			<a
				class="btn btn-primary shadow"
				href="{{ updateAllUrl }}"
				download
				translate
			>
				<i class="pbi-icon-mini pbi-download align-bottom" aria-hidden="true"></i
				>my_products.update_all
			</a>
		</div>

		<table
			matSort
			class="table shadow table-striped table-hover"
			(matSortChange)="sortUpdates($event)"
		>
			<thead class="thead-light">
				<tr>
					<th mat-sort-header="name" id="productName">
						<div translate>my_products.product_name</div>
					</th>
					<th mat-sort-header="version" id="productVersion">
						<div translate>my_products.product_version</div>
					</th>
					<th mat-sort-header="new_version" id="newVersionNumber">
						<div translate>my_products.new_version_header</div>
					</th>
					<th mat-sort-header="product_type" id="productType">
						<div translate>my_products.product_type</div>
					</th>
					<th *ngIf="areThereUpdates()" id="areThereUpdates">
						<div translate>my_products.action</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let p of productUpdates">
					<td class="name" style="cursor: pointer">
						<div
							[routerLink]="['/product/', p.product_info.product_id]"
							class="productrow"
						>
							<img
								src="{{ p.product_info.icon_url }}"
								height="32px"
								style="margin: 2px 5px"
							/>
							{{ p.product_info.product_name }}
						</div>
					</td>

					<td>{{ p.product_version_used }}</td>
					<td>{{ p.product_info.version }}</td>
					<td>{{ p.product_info.item_type_id }}</td>
					<td *ngIf="areThereUpdates()">
						<a
							class="btn btn-primary shadow"
							href="{{ p.download_url }}"
							download
							translate
						>
							<i class="pbi-icon-mini pbi-download align-bottom" aria-hidden="true"></i
							>my_products.update_button_text
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div>
		<h2 translate>my_products.installed_products</h2>
	</div>
	<div *ngIf="loadingMyProducts" translate>my_products.products_loading</div>
	<div *ngIf="!loadingMyProducts">
		<div *ngIf="areThereInstalledProducts()">
			<table
				matSort
				class="table shadow table-striped table-hover"
				(matSortChange)="sortInstalled($event)"
			>
				<thead class="thead-light">
					<tr>
						<th mat-sort-header="name" id="installProductName">
							<div translate>my_products.product_name</div>
						</th>
						<th mat-sort-header="version" id="installProductVersion">
							<div translate>my_products.product_version</div>
						</th>
						<th mat-sort-header="product_type" id="installProductType">
							<div translate>my_products.product_type</div>
						</th>
						<th mat-sort-header="machine_name" id="machineName">
							<div translate>my_products.machine_name</div>
						</th>
						<th mat-sort-header="miproversion" id="miproVersion">
							<div translate>my_products.mipro_version</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let p of myProducts"
						[routerLink]="['/product/', p.product_info.product_id]"
						class="productrow"
					>
						<td class="name">
							<img
								src="{{ p.product_info.icon_url }}"
								height="32px"
								style="margin: 2px 5px"
							/>
							{{ p.product_info.product_name }}
						</td>
						<td>{{ p.product_version_used }}</td>
						<td>{{ p.product_info.item_type_id }}</td>
						<td>{{ p.machine_name }}</td>
						<td>{{ p.mipro_version }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="!areThereInstalledProducts() && isUserLoggedin()" translate>
			my_products.no_installed_products
		</div>
		<div *ngIf="!areThereInstalledProducts() && !isUserLoggedin()" translate>
			my_products.please_log_in
		</div>
	</div>
	<div (click)="LoadProductHistory()" class="historyheader">
		<table>
			<tr>
				<td>
					<h2 translate>my_products.download_history</h2>
				</td>
				<td style="vertical-align: middle">
					<mat-icon style="margin-top: 2rem; margin-bottom: 1rem">{{
						showHistory ? "keyboard_arrow_down" : "keyboard_arrow_right"
					}}</mat-icon>
				</td>
			</tr>
		</table>
	</div>

	<div *ngIf="showHistory">
		<div *ngIf="areThereHistoryRecords()">
			<table
				matSort
				class="table shadow table-striped table-hover"
				(matSortChange)="sortHistory($event)"
			>
				<thead class="thead-light">
					<tr>
						<th mat-sort-header="name">
							<div translate>my_products.product_name</div>
						</th>
						<th mat-sort-header="version">
							<div translate>my_products.product_version</div>
						</th>
						<th mat-sort-header="product_type">
							<div translate>my_products.product_type</div>
						</th>
						<th mat-sort-header="timestamp">
							<div translate>my_products.install_time</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let p of productHistory"
						[routerLink]="
							p.productInfo.deleted ? null : ['/product/', p.productInfo.product_id]
						"
						class="productrow"
					>
						<td class="name">
							<img
								src="{{ p.productInfo.icon_url }}"
								height="32px"
								style="margin: 2px 5px"
							/>
							{{ p.productInfo.product_name }}
						</td>
						<td a>{{ p.productInfo.version }}</td>
						<td>{{ p.productInfo.item_type_id }}</td>
						<td>{{ p.transaction.timestamp }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="!areThereHistoryRecords()">
			<div *ngIf="loadingHistory">
				<p translate>my_products.loading_history</p>
			</div>
			<div *ngIf="!loadingHistory">
				<p translate>my_products.no_history</p>
			</div>
		</div>
	</div>
</div>
