import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "@app/globals";
import { Category, Platform } from "@app/Models/Category";
import { OtherLanguageInfo, ProductItem } from "@app/Models/ProductItem";
import { CategoryService } from "@app/Services/category.service";
import { ProductListService } from "@app/Services/product-list.service";
import { ProductUploadService } from "@app/Services/product-upload.service";
import { TranslateService } from "@ngx-translate/core";
import { MapInfoVersion } from "@app/Models/MapInfoVersion";

import {
	HttpClient,
	HttpRequest,
	HttpErrorResponse,
	HttpResponse,
} from "@angular/common/http";
import { environment } from "@env/environment";
import { MiproVersionService } from "@app/Services/mipro-version.service";


interface ProductVersion {
	displayName: string;
	value: string;
}

@Component({
	selector: "app-product-edit",
	templateUrl: "./product-edit.component.html",
	styleUrls: ["./product-edit.component.sass"],
})
export class ProductEditComponent implements OnInit {
	constructor(
		private router: Router,
		private uploadProductService: ProductUploadService,
		private translate: TranslateService,
		private globals: Globals,
		private route: ActivatedRoute,
		private productService: ProductListService,
		private categoryService: CategoryService,
		private miproVersionService: MiproVersionService,
		private http: HttpClient
	) {
		this.getCategorylist();
		this.productItem.product_long_description = "";
		this.productItem.product_short_description = "";
		this.productItem.whats_new = "";
		this.getMiproVersion();

	}

	miproVerions: MapInfoVersion[] = [];

	selectedLanguages = this.globals.supportedLanguageCodes;

	categories: Category[];
	id: string;
	version: string;
	productItem: ProductItem | null = new ProductItem();
	language: string;
	//   languageAddedToolTip: string;
	//    languageAddedText: string;
	shortDescAreaMaxChar = 255;
	productDescAreaMaxChar = this.globals.descriptionCharLimit;
	whatsNewAreaMaxChar = this.globals.descriptionCharLimit;
	otherLanguageInfo: Map<string, OtherLanguageInfo> = new Map<
		string,
		OtherLanguageInfo
	>();
	dragDropfileType: String;
	dragDropMessage: string;
	dragDropFileTypeMessage: string;
	@Input() productIconFile: File = null;
	productIconSrc: any = null;
	loadingLanguage = true;
	snapshotsEdit: any[] = [];
	isEditSnapshot = true;
	editSnapshotsFiles: any[] = [];
	documentationFile: any = null;
	release_notesFile: any = null;
	legalFile: File = null;
	documentationFilePath = "documentation.pdf";
	legalFilePath = "legal.pdf";
	release_notesPath = "release_notes.pdf";
	documentationFileExistsInServer = true;
	releaseNoteFileExistsInServer = true;
	legalFileExistsInServer = true;
	deleteToolTip: string;
	undoToolTip: string;
	uploadToolTip: string;
	versionFile: File;
	versionFileName = "";
	addinVersions: ProductVersion[] = [];
	selectedPreviousVersion: any;
	previousVersion: string;
	startUploading = false;
	endUploading = false;
	platform: Platform[];
	
	ngOnInit() {
		this.id = this.route.snapshot.queryParamMap.get("id");
		this.version = this.route.snapshot.queryParamMap.get("version");
		this.language = "en";
		this.getProductItem();
		this.dragDropfileType = "image/png";
		this.dragDropMessage = this.translate.instant(
			"DragNDropControl.DragnDropAppIcon"
		);
		this.dragDropFileTypeMessage = this.translate.instant(
			"DragNDropControl.fileTypePng"
		);
		this.deleteToolTip = this.translate.instant("EditProduct.Delete");
		this.undoToolTip = this.translate.instant("EditProduct.Undo");
		this.uploadToolTip = this.translate.instant("EditProduct.Upload");
		this.previousVersion = this.translate.instant("EditProduct.PreviousVersion");
		this.getPreviousVersions();
		this.platform = this.globals.platform;
	}
	async getMiproVersion() {

		this.miproVerions = await (await this.miproVersionService.GetMiproVersionList()).sort(
			function (verA, verB) {
				if (verA.display_name < verB.display_name) {
					return -1;
				}
				if (verA.display_name > verB.display_name) {
					return 1;
				}
				return 0;
			}
		);
	}

	async getPreviousVersions() {
		this.addinVersions.length = 0;
		this.addinVersions.push({ displayName: this.previousVersion, value: "" });

		await this.productService.getAllVersionProducts(this.id).then((value) => {
			value.forEach((element) => {
				if (element.version !== this.version) {
					console.log(element.version);
					this.addinVersions.push({
						displayName: element.version,
						value: element.version,
					});
				}
			});
			this.selectedPreviousVersion = this.addinVersions[0].value;
		});
	}

	getSelectedPreviousVersionVal() {
		this.version = this.selectedPreviousVersion;
		this.productIconSrc = null;
		this.loadingLanguage = true;
		this.snapshotsEdit = [];
		this.isEditSnapshot = true;
		this.editSnapshotsFiles = [];
		this.documentationFile = null;
		this.release_notesFile = null;
		this.legalFile = null;
		this.documentationFileExistsInServer = true;
		this.releaseNoteFileExistsInServer = true;
		this.legalFileExistsInServer = true;
		this.versionFileName = "";
		this.getProductItem();
	}
	async getCategorylist() {
		this.categories = (await this.categoryService.getCategoryList()).sort(
			function (catA, catB) {
				if (catA.category_name < catB.category_name) {
					return -1;
				}
				if (catA.category_name > catB.category_name) {
					return 1;
				}
				return 0;
			}
		);
	}

	async copyDataForEdit(files: string | any[]) {
		if (files != null) {
			for (let index = 0; index < files.length; index++) {
				const fileUrl = files[index];
				const blob = await fetch(fileUrl).then(r => r.blob());
				const obj = {
					url: fileUrl,
					file: blob,
					isCancel: false,
					isExisting: true,
					newIndex: index,
				};
				this.snapshotsEdit.push(obj);
			}
		}
	}


	async getProductItem() {
		try {
			this.productItem = await this.getProduct(
				this.id,
				this.version,
				this.language
			).then((value) => {
				return value;
			});
			if (this.productItem == null) {
				this.globals.ErrorMessage +=
					" " + this.translate.instant("EditProduct.ProductNotFound");
				this.router.navigate(["/product", this.id]);
				return;
			}
			if (this.productItem.product_long_description === undefined) {
				this.productItem.product_long_description = "";
			}
			if (this.productItem.product_short_description === undefined) {
				this.productItem.product_short_description = "";
			}
			if (this.productItem.whats_new === undefined) {
				this.productItem.whats_new = "";
			}
			if(this.productItem.platform === undefined){
				this.productItem.platform = "";
			}
			this.productIconSrc = this.productItem.icon_url;
			this.copyDataForEdit(this.productItem.snapshots_urls);
			// Add the mipro version if it is not in the list
			const result = this.miproVerions.find(
				({ major_version }) => major_version === this.productItem.product_pro_supported_version_from
			);
			if (result == null || result === undefined) {
				const obj = {
					display_name: this.productItem.product_pro_supported_version_from,
					version: this.productItem.product_pro_supported_version_from,
					major_version: this.productItem.product_pro_supported_version_from

				};
				this.miproVerions.push(obj);
			}
			this.updateOtherLanguageInfo();
			//      this.setLanguageToolTip();
			this.IsFileExistsInServer();
		} catch (error) {
			this.globals.WindowAlertErrorMessage(
				" " + this.translate.instant("EditProduct.ProductNotFound")
			);
			this.router.navigate(["/product", this.id]);
			return;
		}
	}

	async IsFileExistsInServer() {
		await this.http
			.get(this.productItem.documentation_url)
			.pipe()
			.subscribe(
				(res) => { },
				(err: HttpErrorResponse) => {
					if (err.status === 200) {
						this.documentationFileExistsInServer = true;
					} else {
						this.documentationFilePath = "";
						this.documentationFileExistsInServer = false;
					}
				}
			);

		await this.http
			.get(this.productItem.release_url)
			.pipe()
			.subscribe(
				(res) => { },
				(err: HttpErrorResponse) => {
					if (err.status === 200) {
						this.releaseNoteFileExistsInServer = true;
					} else {
						this.release_notesPath = "";
						this.releaseNoteFileExistsInServer = false;
					}
				}
			);

		await this.http
			.get(this.productItem.legal_url)
			.pipe()
			.subscribe(
				(res) => { },
				(err: HttpErrorResponse) => {
					if (err.status === 200) {
						this.legalFileExistsInServer = true;
					} else {
						this.legalFilePath = "";
						this.legalFileExistsInServer = false;
					}
				}
			);
	}

	async getProduct(
		id: string,
		version: string,
		lang: string
	): Promise<ProductItem> {
		try {
			if (this.id !== null) {
				// Always fetch latest
				this.globals.productByVersionCacheRefresh = true;
				const results: ProductItem[] = await this.productService.getProductByVersion(
					id,
					version,
					lang,
					false
				);
				return results[0];
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	async StartUpload() {
		this.globals.showProgress = true;
		this.startUploading = true;
		this.endUploading = false;
		this.productFileUpload().then(
			() => { },
			() => {
				// error
				console.log("Error - Icon upload.");
				this.productDocumentUpload();
			}
		);
	}

	async productFileUpload() {
		if (this.versionFile === undefined || this.versionFile == null) {
			this.productIconUpload();
		} else {
			console.log("productFileUpload");
			await this.uploadProductService
				.deleteFile(
					"*.zip",
					this.productItem.product_id,
					this.productItem.version,
					"application/x-zip-compressed"
				)
				.then(
					() => {
						this.uploadProductService
							.uploadFile(
								this.versionFile,
								this.versionFile.name,
								this.productItem.product_id,
								this.productItem.version
							)
							.then(
								() => {
									this.productIconUpload();
								},
								() => {
									this.globals.ErrorMessage += this.translate.instant(
										"ProductFileNotUpdated"
									);
									this.productIconUpload();
								}
							);
					},
					() => {
						console.log("Error - Deleting product file.");
						this.productIconUpload();
					}
				);
		}
	}

	async productIconUpload() {
		console.log("productIconUpload");
		// upload product icon in s3
		await this.uploadProductService
			.uploadFile(
				this.productIconFile,
				"icon.png",
				this.productItem.product_id,
				this.productItem.version
			)
			.then(
				() => {
					this.productDocumentUpload();
				},
				() => {
					console.log("Error - Icon upload.");
					this.productDocumentUpload();
				}
			);
	}
	async productDocumentUpload() {
		console.log("productDocumentUpload");
		// update documentation file
		if (
			!(this.documentationFile == null || this.documentationFile === undefined)
		) {
			await this.uploadProductService
				.uploadFile(
					this.documentationFile,
					"docs/documentation.pdf",
					this.productItem.product_id,
					this.productItem.version
				)
				.then(
					() => {
						this.documentationFilePath = "documentation.pdf";
						delete this.documentationFile;
						this.documentationFileExistsInServer = true;
						this.productReleaseNotesUpload();
					},
					() => {
						console.log("Error - documentation file.");
						this.productReleaseNotesUpload();
					}
				);
		} else {
			// delete file if it exists in server
			if (
				this.documentationFilePath === "" &&
				this.documentationFileExistsInServer
			) {
				await this.uploadProductService
					.deleteFile(
						"docs/documentation.pdf",
						this.productItem.product_id,
						this.productItem.version,
						"application/pdf"
					)
					.then(
						() => {
							this.documentationFileExistsInServer = false;
							this.productReleaseNotesUpload();
						},
						() => {
							console.log("Error - documentation file.");
							this.productReleaseNotesUpload();
						}
					);
			} else {
				this.productReleaseNotesUpload();
			}
		}
	}
	async productReleaseNotesUpload() {
		console.log("productReleaseNotesUpload");
		// update release notes file
		if (
			!(this.release_notesFile == null || this.release_notesFile === undefined)
		) {
			await this.uploadProductService
				.uploadFile(
					this.release_notesFile,
					"docs/release_notes.pdf",
					this.productItem.product_id,
					this.productItem.version
				)
				.then((value) => {
					this.release_notesPath = "release_notes.pdf";
					delete this.release_notesFile;
					this.release_notesFile = null;
					this.releaseNoteFileExistsInServer = true;
				})
				.then(
					() => {
						this.productLegalDocUpload();
					},
					() => {
						console.log("Error - release notes file.");
						this.productLegalDocUpload();
					}
				);
		} else {
			// delete file
			if (this.release_notesPath === "" && this.releaseNoteFileExistsInServer) {
				await this.uploadProductService
					.deleteFile(
						"docs/release_notes.pdf",
						this.productItem.product_id,
						this.productItem.version,
						"application/pdf"
					)
					.then((value) => {
						this.releaseNoteFileExistsInServer = false;
					})
					.then(
						() => {
							this.productLegalDocUpload();
						},
						() => {
							console.log("Error - release notes file.");
							this.productLegalDocUpload();
						}
					);
			} else {
				this.productLegalDocUpload();
			}
		}
	}
	async productLegalDocUpload() {
		console.log("productLegalDocUpload");
		// update legal file
		if (!(this.legalFile == null || this.legalFile === undefined)) {
			await this.uploadProductService
				.uploadFile(
					this.legalFile,
					"docs/legal.pdf",
					this.productItem.product_id,
					this.productItem.version
				)
				.then((value) => {
					this.legalFilePath = "legal.pdf";
					delete this.legalFile;
					this.legalFile = null;
					this.legalFileExistsInServer = true;
				})
				.then(
					() => {
						this.productSnapshotsUpload();
					},
					() => {
						console.log("Error - legal file.");
						this.productSnapshotsUpload();
					}
				);
		} else {
			// delete file
			if (this.legalFilePath === "" && this.legalFileExistsInServer) {
				await this.uploadProductService
					.deleteFile(
						"docs/legal.pdf",
						this.productItem.product_id,
						this.productItem.version,
						"application/pdf"
					)
					.then((value) => {
						this.legalFileExistsInServer = false;
					})
					.then(
						() => {
							this.productSnapshotsUpload();
						},
						() => {
							console.log("Error - legal file.");
							this.productSnapshotsUpload();
						}
					);
			} else {
				this.productSnapshotsUpload();
			}
		}
	}

	async productSnapshotsUpload() {

		if (this.editSnapshotsFiles.length > 0) {

			// deleting existing snapshots
			for (let index = 0; index < this.editSnapshotsFiles.length; index++) {

				if (this.editSnapshotsFiles[index].isExisting) {
					const name = "snapshots/snapshot" + index + ".png";
					console.log("delete:" + name);
					this.uploadProductService.deleteFile(
						name,
						this.productItem.product_id,
						this.productItem.version,
						"image/png"
					);
					this.editSnapshotsFiles[index].isExisting = false;
				}
			}
			// upload Snapshots  in s3
			for (let index = 0; index < this.editSnapshotsFiles.length; index++) {
				if (this.editSnapshotsFiles[index].isCancel == false) {
					const file = this.editSnapshotsFiles[index].file;
					const name = "snapshots/snapshot" + this.editSnapshotsFiles[index].newIndex + ".png";
					this.uploadProductService.uploadFile(
						this.editSnapshotsFiles[index].file,
						name,
						this.productItem.product_id,
						this.productItem.version
					);
					this.editSnapshotsFiles[index].isExisting = true;
				}

			}
		}
		this.productMetadataUpload();
	}

	productMetadataUpload() {
		console.log("productMetadataUpload");
		// Update Product metadata at the end.
		// add metadata in dynamodb tables
		this.uploadProductService
			.putProductMetadata(this.productItem, this.language, this.otherLanguageInfo)
			.then(
				() => {
					this.endUpload();
				},
				() => {
					this.globals.WindowAlertErrorMessage(
						this.translate.instant("ErrorMetadataUpload")
					);
					console.log("Error - Metadata upload.");
					this.endUpload();
				}
			);
	}
	endUpload() {
		this.globals.showProgress = false;
		this.startUploading = false;
		this.endUploading = true;
		console.log("endUpload");
	}

	async saveProduct() {
		try {
			console.log("StartUpload");
			this.StartUpload().then(() => {
				console.log("End");
			});
			// this.router.navigate(['/product', 	this.id]);
		} catch (error) {
			this.globals.WindowAlertErrorMessage(error);
			this.router.navigate(["/product", this.id]);
		}
	}

	save() {
		this.saveProduct();
	}

	showIconImage(file: File) {
		try {
			if (file != null) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (_event) => {
					this.productIconSrc = reader.result;
				};
			} else {
				this.productIconSrc = this.productItem.icon_url;
			}
		} catch (error) {
			this.productIconSrc = this.productItem.icon_url;
		}
	}

	getFileFromControl(inFile: File) {
		try {
			if (inFile != null) {
				this.productIconFile = inFile;
				this.showIconImage(this.productIconFile);
			} else {
				this.productIconSrc = this.productItem.icon_url;
				this.productIconFile = null;
			}
		} catch (error) {
			this.productIconSrc = this.productItem.icon_url;
			this.productIconFile = null;
		}
	}
	cancelUpdateAppIconModalDialog() {
		delete this.productIconFile;
		this.productIconFile = null;
		this.showIconImage(this.productIconFile);
	}
	// Language relates code
	clearLanguage() {
		if (this.otherLanguageInfo.has(this.language)) {
			this.otherLanguageInfo.delete(this.language);
		}
		// update other language info
		const languageInfo = new OtherLanguageInfo();
		languageInfo.language = this.language;
		languageInfo.product_long_description = "";
		languageInfo.product_short_description = "";
		languageInfo.whats_new = "";
		languageInfo.product_name = "";
		this.otherLanguageInfo.set(this.language, languageInfo);
		// update UX
		this.productItem.product_name = "";
		this.productItem.product_long_description = "";
		this.productItem.product_short_description = "";
		this.productItem.whats_new = "";
		// this.setLanguageToolTip();
	}
	// This function is called to update the other languauage info when any of the language
	// dependend text is changed
	updateOtherLanguageInfo() {
		if (this.otherLanguageInfo.has(this.language)) {
			const value = this.otherLanguageInfo.get(this.language);
			value.product_name = this.productItem.product_name;
			value.product_long_description = this.productItem.product_long_description;
			value.product_short_description = this.productItem.product_short_description;
			value.whats_new = this.productItem.whats_new;
			this.otherLanguageInfo.set(this.language, value);
		} else {
			const languageInfo = new OtherLanguageInfo();
			languageInfo.product_name = this.productItem.product_name;
			languageInfo.language = this.language;
			languageInfo.product_long_description = this.productItem.product_long_description;
			languageInfo.product_short_description = this.productItem.product_short_description;
			languageInfo.whats_new = this.productItem.whats_new;
			this.otherLanguageInfo.set(languageInfo.language, languageInfo);
			// this.setLanguageToolTip();
		}
	}
	async changeLanguage(element) {
		if (this.otherLanguageInfo.has(this.language)) {
			const value = this.otherLanguageInfo.get(this.language);
			this.productItem.product_long_description = value.product_long_description;
			this.productItem.product_name = value.product_name;
			this.productItem.product_short_description = value.product_short_description;
			this.productItem.whats_new = value.whats_new;
		} else {
			try {
				this.loadingLanguage = false;
				// try to get data from DB
				const productItem = await this.getProduct(
					this.id,
					this.version,
					this.language
				).then((value) => {
					this.loadingLanguage = true;
					return value;
				});
				const languageInfo = new OtherLanguageInfo();
				languageInfo.language = this.language;
				if (productItem != null) {
					languageInfo.product_long_description =
						productItem.product_long_description;
					languageInfo.product_short_description =
						productItem.product_short_description;
					languageInfo.whats_new = productItem.whats_new;
					languageInfo.product_name = productItem.product_name;
				} else {
					languageInfo.product_name = "";
					languageInfo.product_long_description = "";
					languageInfo.product_short_description = "";
					languageInfo.whats_new = "";
				}
				// update otherLanguageInfo
				this.otherLanguageInfo.set(this.language, languageInfo);
				// set to UX
				this.productItem.product_name = languageInfo.product_name;
				this.productItem.product_long_description =
					languageInfo.product_long_description;
				this.productItem.product_short_description =
					languageInfo.product_short_description;
				this.productItem.whats_new = languageInfo.whats_new;
			} catch (error) {
				this.productItem.product_name = "";
				this.productItem.product_long_description = "";
				this.productItem.product_short_description = "";
				this.productItem.whats_new = "";
				console.error(error);
				this.globals.WindowAlertErrorMessage(
					this.translate.instant("EditProduct.LanguageNotFound")
				);
				// Reset value of loadingLanguage.
				this.loadingLanguage = true;
			}
		}
	}

	// editing snapshots
	getSnapshotsFileFromControl(inFile: File[]) {
		if (inFile != null) {
			this.editSnapshotsFiles = inFile;
		}
	}

	docDelete() {
		if (this.documentationFile != null) {
			delete this.documentationFile;
			this.documentationFile = null;
		}
		this.documentationFilePath = "";
	}
	undoDocClicked() {
		if (this.documentationFile != null) {
			this.documentationFile = null;
		}
		if (this.documentationFileExistsInServer) {
			this.documentationFilePath = "documentation.pdf";
		} else {
			this.documentationFilePath = "";
		}
	}
	docUpload(files: any) {
		if (files[0] != null) {
			this.documentationFile = files[0];
			this.documentationFilePath = this.documentationFile.name;
		}
	}
	legalDocUpload(files: any) {
		if (files[0] != null) {
			this.legalFile = files[0];
			this.legalFilePath = this.legalFile.name;
		}
	}
	undoLegalClicked() {
		if (this.legalFile != null) {
			this.legalFile = null;
		}

		if (this.legalFileExistsInServer) {
			this.legalFilePath = "legal.pdf";
		} else {
			this.legalFilePath = "";
		}
	}
	legalDelete() {
		if (this.legalFile != null) {
			delete this.legalFile;
			this.legalFile = null;
		}
		this.legalFilePath = "";
	}
	releaseNotesUpload(files: any) {
		if (files[0] != null) {
			this.release_notesFile = files[0];
			this.release_notesPath = this.release_notesFile.name;
		}
	}
	undoReleseNotesClicked() {
		if (this.release_notesFile != null) {
			this.release_notesFile = null;
		}
		if (this.releaseNoteFileExistsInServer) {
			this.release_notesPath = "release_notes.pdf";
		} else {
			this.release_notesPath = "";
		}
	}
	releaseNotesDelete() {
		if (this.release_notesFile != null) {
			delete this.release_notesFile;
			this.release_notesFile = null;
		}
		this.release_notesPath = "";
	}
	updateVersionFile(files: any) {
		for (const file of files) {
			if ("application/x-zip-compressed" === file.type) {
				this.versionFile = file;
				this.versionFileName = file.name;
			} else {
				this.globals.ErrorMessage += this.translate.instant(
					"DragNDropControl.invalid_file_selected"
				);
			}
		}
	}
	productFileDelete() {
		this.versionFile = null;
		this.versionFileName = "";
	}
}
