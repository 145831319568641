import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileHandle } from '../Directives/files-drag-drop.directive';
import { Globals } from "@app/globals";

@Component({
	selector: "app-files-upload-preview",
	templateUrl: "./files-upload-preview.component.html",
	styleUrls: ["./files-upload-preview.component.css"],
})
export class FilesUploadPreviewComponent implements OnInit {
	@Output() messageToEmit = new EventEmitter<File[]>();
	@Input() files: File[] = [];
	@Input() editSnapshotsFiles: any[] = [];
	@Input() isEdit: boolean;
	browse: String;
	snapshotsUrl: any[] = [];
	snapshotsFile: File[] = [];
	fileType: String;
	errorMaxScreenshots: String;
	snapshotsEdit: any[] = [];
	dragDropFileTypeMessage: String;

	constructor(
		private translate: TranslateService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.fileType = "image/png";
		this.browse = this.translate.instant("FilesUploadPreview.Browse");
		this.errorMaxScreenshots = this.translate.instant(
			"FilesUploadPreview.MaxScreenshots"
		);
		this.snapshotsFile = [];
		if (this.files != null) {
			for (const file of this.files) {
				this.snapshotsFile.push(file);
				const url = this.sanitizer.bypassSecurityTrustUrl(
					window.URL.createObjectURL(file)
				);
				this.snapshotsUrl.push(url);
			}
		}
		this.dragDropFileTypeMessage = this.translate.instant(
			"DragNDropControl.fileTypePng"
		);
	}
	// Uploading snapshots file
	onUpload(event) {
		const uploadedFiles = event.target.files;
		if (uploadedFiles) {
			if (uploadedFiles.length + this.snapshotsFile.length > 4) {
				window.alert(this.errorMaxScreenshots);
			} else {
				for (const file of uploadedFiles) {
					if ("image/png" === file.type) {
						const url = this.sanitizer.bypassSecurityTrustUrl(
							window.URL.createObjectURL(file)
						);
						this.snapshotsUrl.push(url);
						this.snapshotsFile.push(file);
					} else {
						window.alert(
							this.translate.instant("FilesUploadPreview.InvalidFileWarning")
						);
					}
				}
				if (this.snapshotsFile.length > 0) {
					this.messageToEmit.emit(this.snapshotsFile);
				}
			}
		}
	}
	// Dropping snapshots file
	filesDropped(droppedFiles: FileHandle[]): void {
		if (droppedFiles != null) {
			if (droppedFiles.length + this.snapshotsFile.length > 4) {
				window.alert(this.errorMaxScreenshots);
			} else {
				for (const fileObj of droppedFiles) {
					this.snapshotsFile.push(fileObj.file);
					this.snapshotsUrl.push(fileObj.url);
				}
				if (this.snapshotsFile.length > 0) {
					this.messageToEmit.emit(this.snapshotsFile);
				}
			}
		}
	}
	// Removing snapshots file
	onRemove(selectedFile) {
		if (selectedFile != null) {
			const index = this.snapshotsUrl.indexOf(selectedFile);
			if (index >= 0) {
				this.snapshotsUrl.splice(index, 1);
				this.snapshotsFile.splice(index, 1);
			}
			if (this.snapshotsFile.length > 0) {
				this.messageToEmit.emit(this.snapshotsFile);
			}
		}
	}
	
	// check whether to display snapshots or not
	isDisplay(file) {
		if (file.isCancel === true) {
			return false;
		}
		return true;
	}

	// Edit Snapshots
	onUploadEdit(event) {
		const uploadedFiles = event.target.files;
		if (uploadedFiles) {
			if (uploadedFiles.length + this.cntOfUpdatedFile() > 4) {
				window.alert(this.errorMaxScreenshots);
			} else {
				for (const file of uploadedFiles) {
					if ("image/png" === file.type) {
						const url = this.sanitizer.bypassSecurityTrustUrl(
							window.URL.createObjectURL(file)
						);
						let currentIndex = -1;
						if (this.editSnapshotsFiles.length == 0) {
							currentIndex = 0;
						} else {
							currentIndex = this.editSnapshotsFiles[this.editSnapshotsFiles.length - 1].newIndex + 1;
						}
						const obj = {
							url: url,
							file: file,
							isCancel: false,
							isExisting: false,
							newIndex: currentIndex,
						};
						this.editSnapshotsFiles.push(obj);

					} else {
						window.alert(
							this.translate.instant("FilesUploadPreview.InvalidFileWarning")
						);
					}
				}
				if (this.editSnapshotsFiles.length > 0) {
					this.messageToEmit.emit(this.editSnapshotsFiles);
				}
			}
		}
	}

	// counting of files to be uploaded
	cntOfUpdatedFile() {
		let cnt = 0;
		for (const file of this.editSnapshotsFiles) {
			if (file.isCancel === false) {
				cnt++;
			}
		}
		return cnt;
	}

	// Deleting  snapshots file while editing
	onRemoveEdit(selectedFile) {
		if (selectedFile != null) {
			const index = this.editSnapshotsFiles.indexOf(selectedFile);
			if (index >= 0) {
				this.editSnapshotsFiles[index].isCancel = true;
				for (let currentIndex = index; currentIndex < this.editSnapshotsFiles.length; currentIndex++) {
					this.editSnapshotsFiles[currentIndex].newIndex -= 1;
				}
			}
			if (this.editSnapshotsFiles.length > 0) {
				this.messageToEmit.emit(this.editSnapshotsFiles);
			}
		}
	}
}

	
