import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { Globals } from "@app/globals";
import {
	MapInfoVersion
} from "@app/Models/MapInfoVersion";

@Injectable({
	providedIn: "root",
})
export class MiproVersionService {
	constructor(private http: HttpClient, private globals: Globals) {}

	async GetMiproVersionList(): Promise<MapInfoVersion[]> {
		const urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.MiproVersionEndPoint;
		
		let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
			(err: HttpErrorResponse) => {
			  this.globals.HttpErrorResponseHandling(err.status);
		  });
		return JSON.parse(x.body);
	}
}
