import { Timestamp } from "rxjs";
import { ProductItem } from "./ProductItem";

export class UserTransaction {
	locale_code: string;
	version: string;
	timestamp: string;
	device_id: string;
	device_name: string;
	product_id: string;
	mipro_version: string;
	subscriber_id: string;
}

export class UserProductHistory {
	transaction: UserTransaction;
	productInfo: ProductItem;
}
