export class Category {
	category_id: string;
	description: string;
	category_name: string;
	locale: string;
};
export class Platform {
	id: string;
	display_name: string;
}
