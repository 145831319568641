import { Component, OnInit } from "@angular/core";
import { ProductItem } from "@app/Models/ProductItem";
import { ProductListService } from "@app/Services/product-list.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductUrl } from "@app/Models/ProductUrl";
import { ProductUrlService } from "@app/Services/product-url.service";
import { ProductDeleteService } from "@app/Services/product-delete.service";
import { CategoryService } from "@app/Services/category.service";
import { Globals } from "@app/globals";
import { TranslateService } from "@ngx-translate/core";
import { ClientInstallInfoService } from "@app/Services/client-install-info.service";
import { environment } from "../../environments/environment";

@Component({
	selector: "app-product",
	templateUrl: "./product.component.html",
	styleUrls: ["./product.component.sass"],
})
export class ProductComponent implements OnInit {
	constructor(
		private productService: ProductListService,
		private productUrlService: ProductUrlService,
		private clientInstallService: ClientInstallInfoService,
		private productDeleteService: ProductDeleteService,
		private catService: CategoryService,
		private route: ActivatedRoute,
		private router: Router,
		private globals: Globals,
		private translate: TranslateService
	) {
		this.globals.showProgress = true;
	}

	selectedProductId: string | null;
	selectedProduct: ProductItem | null = new ProductItem();
	selectProductWebsiteDomain: string;
	selectedProductUrl: ProductUrl = new ProductUrl();
	selectedProductCategoryString = "";
	selectedProductPlatform = "";
	buttonText: string;
	noVersionForPro = false;
	showAdminControls = environment.AdminAvailable;
	buttonTextDownloadProduct: string;
	buttonTextUpgradePro: string;
	buttonTextInstall: string;
	buttonTextUpgradeProduct: string;
	buttonTextReinstallProduct: string;
	buttonTextGoToProductPage: string;
	deleteProductWarningDialogTitle: string;
	deleteProductWarningDialogMessage: string;
	slideIndex = 0;
	delete: string;
	cancel: string;
	manage_version: string;
	previous_versions: string;
	manageVersionTableColsHeader: any[];
	productItems: ProductItem[]; // For all versions of product
	manageVersionTableRows: any[] = []; // used in manage version dialog
	selectedVersions: any[] = []; // selected version in manage version grid
	version_no: string;
	data_created: string;
	productLoaded = false;
	noRecordMsgFlag: boolean;
	TruncateProductWebsite(websiteUrl: string): string {
		const parser = document.createElement("a");
		parser.href = websiteUrl !== undefined ? websiteUrl : "";
		return parser.hostname;
	}

	ngOnInit() {
		this.translate
			.get([
				"product_page.download_product",
				"product_page.upgrade_pro",
				"product_page.install_button",
				"product_page.upgrade_button",
				"product_page.reinstall_button",
				"product_page.source_code_link",
				"product_page.delete_product_warning_dialog_title",
				"product_page.delete_product_warning_dialog_message",
				"product_page.delete",
				"product_page.cancel",
				"product_page.manage_version",
				"product_page.previous_versions",
				"product_page.version_no",
				"product_page.data_created",
			])
			.subscribe((data) => {
				this.buttonTextDownloadProduct = data["product_page.download_product"];
				this.buttonTextUpgradePro = data["product_page.upgrade_pro"];
				this.buttonTextInstall = data["product_page.install_button"];
				this.buttonTextUpgradeProduct = data["product_page.upgrade_button"];
				this.buttonTextReinstallProduct = data["product_page.reinstall_button"];
				this.buttonTextGoToProductPage = data["product_page.source_code_link"];
				this.buttonText = this.buttonTextDownloadProduct;
				this.deleteProductWarningDialogTitle =
					data["product_page.delete_product_warning_dialog_title"];
				this.deleteProductWarningDialogMessage =
					data["product_page.delete_product_warning_dialog_message"];
				this.delete = data["product_page.delete"];
				this.cancel = data["product_page.cancel"];
				this.manage_version = data["product_page.manage_version"];
				this.previous_versions = data["product_page.previous_versions"];
				this.version_no = data["product_page.version_no"];
				this.data_created = data["product_page.data_created"];
				this.manageVersionTableColsHeader = [
					{ field: "version", header: this.version_no },
					{ field: "date_creation", header: this.data_created },
				];
			});

		this.getProductDetails();
	}

	async getProductDetails() {
		this.selectedProductId = this.route.snapshot.paramMap.get("id");
		this.productLoaded = false;

		this.manageVersionTableRows.length = 0;
		this.selectedVersions.length = 0;
		this.noRecordMsgFlag = true;

		const productVersionCollection = await this.productService.getAllVersionProducts(
			this.selectedProductId
		);

		const res = Math.max.apply(
			Math,
			productVersionCollection.map(function (o) {
				return o.version;
			})
		);
		this.selectedProduct = productVersionCollection.find(function (o) {
			return o.version === res;
		});

		if (this.selectedProductId !== null) {
			this.selectedProduct = await this.productService.getProduct(
				this.selectedProductId
			);

			if (this.selectedProduct === undefined || this.selectedProduct === null) {
				this.selectedProduct = await this.productService.getProduct(
					this.selectedProductId,
					"all"
				);

				this.noVersionForPro = true;
				this.buttonText = this.buttonTextUpgradePro;
			}

			if (this.selectedProduct !== undefined && this.selectedProduct !== null) {
				// Set the Platform for the product
				const platform = this.globals.platform.find(
					(x) => x.id === this.selectedProduct.platform
				);
				if (platform !== undefined && platform !== null) {
					this.selectedProductPlatform = platform.display_name;
				}

				// Make the Website URL look pretty and not take too much room.
				if (
					this.selectedProduct.website !== undefined &&
					this.selectedProduct.website !== null
				) {
					if (
						!(
							this.selectedProduct.website
								.toLowerCase()
								.trim()
								.startsWith("http://") ||
							this.selectedProduct.website.toLowerCase().trim().startsWith("https://")
						)
					) {
						this.selectedProduct.website = "http://" + this.selectedProduct.website;
					}
					this.selectProductWebsiteDomain = this.TruncateProductWebsite(
						this.selectedProduct.website
					);
				}

				if (!this.noVersionForPro) {
					if (this.selectedProduct.item_type_id === "SourceCode") {
						this.selectedProductUrl = new ProductUrl();
						this.selectedProductUrl.presignedURL = this.selectedProduct.website;
					} else {
						this.selectedProductUrl = await this.productUrlService.getProductUrl(
							this.selectedProduct.product_id,
							this.selectedProduct.version
						);
					}
				}

				// This will set the appropriate button text for the install button
				if (this.selectedProduct.item_type_id === "SourceCode") {
					this.buttonText = this.buttonTextGoToProductPage;
				} else {
					if (
						this.noVersionForPro === false &&
						this.globals.MachineName &&
						this.globals.IsUserLoggedIn()
					) {
						// Default Label for the button when viewed via the Client in MIPro
						this.buttonText = this.buttonTextInstall;

						const installInfo = await this.clientInstallService.GetInstallInfoList(
							this.globals.User.id
						);

						const productVersion = installInfo.find(
							(x) =>
								x.machine_name === this.globals.MachineName &&
								x.mipro_version === this.globals.ProVersion
						);

						if (productVersion !== undefined && productVersion !== null) {
							const latestProductionVersion = productVersion.product_version_info.find(
								(y) => y.product_id === this.selectedProductId
							);

							if (
								latestProductionVersion !== undefined &&
								latestProductionVersion !== null
							) {
								if (
									this.selectedProduct !== null &&
									latestProductionVersion.version < this.selectedProduct.version
								) {
									this.buttonText = this.buttonTextUpgradeProduct;
								} else {
									this.buttonText = this.buttonTextReinstallProduct;
								}
							}
						}
					}
				}

				// MAKE SURE YOU DO EVERYTHING YOU NEED TO BEFORE LOADING THE CATEGORIES !!!!
				// This is a relative expensive call and will cause anything done after this to wait for the category call to compelte first.

				// Set the Categories for the product
				if (
					this.selectedProduct.product_category_tags_id !== undefined &&
					this.selectedProduct.product_category_tags_id !== null
				) {
					let count = 0;

					for (const cId of this.selectedProduct.product_category_tags_id) {
						if (count > 0) {
							this.selectedProductCategoryString += ", ";
						}

						this.selectedProductCategoryString += (
							await this.catService.getCategoryById(cId)
						).category_name;
						count++;
					}
				}
			} else {
				this.router.navigate(["/404"]);
			}
		} else {
			this.router.navigate(["/404"]);
		}
		productVersionCollection.forEach((element) => {
			if (
				element.version != null &&
				element.version !== undefined &&
				this.selectedProduct.version != null &&
				this.selectedProduct.version !== undefined
			) {
				if (element.version.trim() !== this.selectedProduct.version.trim()) {
					this.manageVersionTableRows.push({
						version: element.version,
						date_creation: element.created_datetime_stamp,
						whatsnew: element.whats_new,
						short_decs: element.product_short_description,
					});
					this.noRecordMsgFlag = false;
				}
			}
		});

		this.productLoaded = true;
		this.globals.showProgress = false;
	}

	manageVersionDialogDelete() {
		let index = 0;
		this.selectedVersions.forEach((element) => {
			this.productDeleteService
				.deleteProduct(this.selectedProduct.product_id, element.version)
				.then(() => {
					index++;
					if (this.selectedVersions.length === index) {
						this.getProductDetails();
					}
				});
		});
	}
	async DeleteProduct() {
		this.productDeleteService
			.deleteProduct(this.selectedProduct.product_id, this.selectedProduct.version)
			.then(
				() => {
					this.router.navigate([""]);
				},
				() => {}
			);
	}

	navigateToEditProduct() {
		this.router.navigate(["/editproduct"], {
			queryParams: {
				id: this.selectedProduct.product_id,
				version: this.selectedProduct.version,
			},
		}); // .then( (e) => {
	}

	openModal() {
		const modalElement = document.getElementById("myModal");
		if (modalElement !== null) {
			modalElement.style.display = "block";
		}
	}

	closeModal() {
		const modalElement = document.getElementById("myModal");
		if (modalElement !== null) {
			modalElement.style.display = "none";
		}
	}

	plusSlides(n: number) {
		this.showSlides((this.slideIndex += n));
	}

	currentSlide(n: number) {
		this.showSlides((this.slideIndex = n));
	}

	showSlides(n: number) {
		let i: number;
		const slides: HTMLCollection = document.getElementsByClassName("mySlides");

		if (n > slides.length - 1) {
			this.slideIndex = 0;
		}
		if (n < 0) {
			this.slideIndex = slides.length - 1;
		}
		for (i = 0; i < slides.length; i++) {
			(slides[i] as HTMLElement).style.display = "none";
		}
		(slides[this.slideIndex] as HTMLElement).style.display = "block";
	}
	addNewVersion() {
		this.router.navigate(["/addproduct"], {
			queryParams: {
				id: this.selectedProduct.product_id,
				version: this.selectedProduct.version,
			},
		});
	}
}
