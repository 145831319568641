<div
	class="dropzone add"
	*ngIf="!isEdit"
	appFilesDragDrop
	(droppedService)="filesDropped($event)"
>
	<div
		class="img-wrap mx-2 d-inline"
		style="position: relative;"
		*ngFor="let file of snapshotsUrl"
	>
		<img [src]="file" width="120" />
		<span class="close pbi-icon-mini x" (click)="onRemove(file)">&times;</span>
	</div>

	<div style="display: inline-block;">
		<div>
			<i class="pbi-icon-outline pbi-download x4"></i>
		</div>
		<label class="d-inline" translate
			>FilesUploadPreview.FilesUploadMessage</label
		>
		<div>
			<input
				type="file"
				id="uploadSnapshot"
				translate
				name="upload"
				(change)="onUpload($event)"
				multiple
				class="hideFileUpload"
				accept="{{ fileType }}"
			/>
			<a
				href=""
				onclick="document.getElementById('uploadSnapshot').click(); return false"
				translate
				>FilesUploadPreview.Browse</a
			>
		</div>
	</div>
</div>

<div class="dropzone edit" *ngIf="isEdit">
	<div
		class="img-wrap mx-2 d-inline"
		style="position: relative;"
		*ngFor="let file of editSnapshotsFiles; index as i"
	>
		<img
			*ngIf="isDisplay(file)"
			[src]="file.url"
			width="120"
			style="vertical-align: middle;"
		/>
		<span
			*ngIf="isDisplay(file)"
			class="close pbi-icon-mini x"
			(click)="onRemoveEdit(file, i)"
			>&times;</span
		>
	</div>

	<div style="display: inline-block; vertical-align: middle;">
		<div>
			<i class="pbi-icon-outline pbi-download x4"></i>
		</div>
		<label class="d-inline" translate
			>FilesUploadPreview.FilesUploadMessage</label
		>
		<div>
			<input
				type="file"
				id="uploadSnapshot"
				translate
				name="upload"
				(change)="onUploadEdit($event)"
				multiple
				class="hideFileUpload"
				accept="{{ fileType }}"
			/>
			<a
				href=""
				onclick="document.getElementById('uploadSnapshot').click(); return false"
				translate
				>FilesUploadPreview.Browse</a
			>
		</div>
		<label translate>{{ dragDropFileTypeMessage }}</label>
	</div>
</div>
