import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { ProductUrl } from "@app/Models/ProductUrl";
import { Globals } from "@app/globals";

@Injectable({
	providedIn: "root"
})
export class ProductUrlService {
	constructor(private http: HttpClient, private globals: Globals) {}

	async getProductUrl(
		productId: string,
		productVersion: string | null
	): Promise<ProductUrl> {
		if (productId != null && productId !== undefined) {
			let urlValue =
				environment.ApiUrl +
				environment.ApiVersion +
				environment.ProductUrlEndpoint;

			urlValue = this.globals.AddParamToUrl(urlValue, "product_id", productId);

			if (productVersion != null && productVersion !== undefined) {
				urlValue = this.globals.AddParamToUrl(urlValue, "version", productVersion);
			} else {
				urlValue = this.globals.AddParamToUrl(urlValue, "version", "1.0");
			}

			let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
				(err: HttpErrorResponse) => {
				  this.globals.HttpErrorResponseHandling(err.status);
			  });
			return JSON.parse(x.body);
		} else {
			return new ProductUrl();
		}
	}
}
