import { Component, OnInit } from "@angular/core";
import { ProductItem } from "@app/Models/ProductItem";
import { ProductListService } from "@app/Services/product-list.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Category } from "@app/Models/Category";
import { CategoryService } from "@app/Services/category.service";
import { ToastrService } from "ngx-toastr";
import { ProductDownloadService } from "@app/Services/product-download.service";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "@app/globals";
import { environment } from "../../environments/environment";

@Component({
	selector: "app-product-field",
	templateUrl: "./product-field.component.html",
	styleUrls: ["./product-field.component.sass"],
})
export class ProductFieldComponent implements OnInit {
	showPromo = true;

	products: ProductItem[] = [];
	selectedCategory: Category = null;
	categoryId: string;
	platformId: string;
	route: string;
	showAdminControls = environment.AdminAvailable;

	private productsLoading: boolean = true;

	constructor(
		private productListService: ProductListService,
		private activatedRoute: ActivatedRoute,
		private categoryService: CategoryService,
		private router: Router,
		private toastr: ToastrService,
		private downloadUrlService: ProductDownloadService,
		private translate: TranslateService,
		private globals: Globals
	) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.globals.showProgress = true;
	}
	ngOnInit() {
		this.showProductList();
	}

	async showProductList() {
		this.categoryId = this.activatedRoute.snapshot.paramMap.get("categoryid");
		this.platformId = this.activatedRoute.snapshot.paramMap.get("platformid");

		if (this.categoryId != null && this.categoryId !== undefined) {
			this.selectedCategory = await this.categoryService.getCategoryById(
				this.categoryId
			);

			this.showPromo = false;
		} else {
			this.showPromo = true;
		}

		if (this.platformId !== null && this.platformId !== undefined) {
			this.products = await this.productListService.getProductsByPlatform(
				this.platformId,
				this.categoryId
			);
			this.translate
				.get("products.platform_" + this.platformId)
				.subscribe((text: string) => {
					if (
						this.selectedCategory !== null &&
						this.selectedCategory !== undefined
					) {
						this.selectedCategory.category_name = text;
					}
				});
		} else {
			this.products = await this.productListService.getProductList(
				this.categoryId
			);
		}
		this.globals.showProgress = false;
		this.productsLoading = false;
	}

	featuredProducts() {
		return this.sorted(
			this.products.filter((x) => x.product_isfeatured === "True")
		);
	}

	sorted(products: ProductItem[]) {
		return products.sort(function (product1, product2) {
			if (product1.product_name < product2.product_name) {
				return -1;
			}
			if (product1.product_name > product2.product_name) {
				return 1;
			}
			return 0;
		});
	}

	filterMarketClient() {
		return this.sorted(
			this.products.filter(
				(x) => x.product_id !== this.globals.MarketplaceClientProductId
			)
		);
	}

	productsByPlatform(platformId: string) {
		const results: ProductItem[] = this.filterMarketClient().filter(
			(product) => product.platform !== undefined
		);
		return results.filter((y) => y.platform === platformId);
	}

	navigateToAddProduct() {
		this.router.navigate(["/addproduct"]);
	}

	on(div: string) {
		this.off();
		document.getElementById(div).style.display = "block";
	}

	off() {
		const list = document.getElementsByClassName("overlay");
		for (let i = 0; i < list.length; i++) {
			const o = list[i];
			(o as HTMLElement).style.display = "none";
		}
	}

	async downloadProduct(productId: string) {
		const url = await this.downloadUrlService.getProductDownloadurl(productId);
		window.open(url + "&product_id=" + productId);
		this.showToastrInfo();
	}

	showToastrInfo() {
		this.toastr.info("The selected product is being installed.");
	}
}
