import { Component, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import "rxjs/";
import { ProductSearchService } from "@app/Services/product-search.service";
import { ProductItem } from "@app/Models/ProductItem";
import { Globals } from "@app/globals";

@Component({
	selector: "app-search-results",
	templateUrl: "./search-results.component.html",
	styleUrls: ["./search-results.component.sass"]
})
export class SearchResultsComponent implements OnInit {
	searchText: string;
	searchResults: ProductItem[] = [];
	waitingFlag = false;

	constructor(
		private route: ActivatedRoute,
		private productSearchService: ProductSearchService,
		private globals: Globals
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe(queryParams => {
			this.searchText = queryParams["searchtext"];
			this.GetProductListFromSearch(this.searchText);
		});
	}

	async GetProductListFromSearch(searchTerms: string) {
		this.globals.showProgress = true;
		this.waitingFlag = true;
		if (searchTerms != null && searchTerms !== undefined && searchTerms !== "") {
			this.searchResults = (await this.productSearchService.getProductList(
				searchTerms
			)).filter(x => x.product_id !== "1");
		}
		this.waitingFlag = false;
		this.globals.showProgress = false;

	}
}
