import { ProductItem } from "./ProductItem";

export class MyProductInfo {
	user_id: string;
	machine_name: string;
	mipro_version: string;
	product_version_used: string;
	product_info: ProductItem;
	download_url: string;
}

export class ClientInstallInfo {
	user_id: string;
	machine_name: string;
	mipro_version: string;
	product_version_info: ClientProductInfo[];
}

export class ClientProductInfo {
	product_id: string;
	version: string;
}
