import { Component, OnInit, HostBinding } from "@angular/core";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.sass"]
})
export class FooterComponent implements OnInit {
	@HostBinding("class.site-footer") true;

	// add the first date of the copyright
	// if it's the current year, you will get a single © date
	// if it's earlier, you get a date range ending in the current year
	private startYear = 2019;
	public date: string;

	constructor() {}

	ngOnInit() {
		const currentYear: number = new Date().getFullYear();
		this.date =
			this.startYear === currentYear
				? `${currentYear}`
				: `${this.startYear}-${currentYear}`;
	}
}
