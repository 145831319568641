import { Component, OnInit } from "@angular/core";
import { MyProductInfo } from "@app/Models/ClientInstallInfo";
import { ClientInstallInfoService } from "@app/Services/client-install-info.service";
import { Globals } from "@app/globals";
import { environment } from "@env/environment";

import { Sort } from "@angular/material/sort";
import { UserTransactionServiceService } from "@app/Services/user-transaction-service.service";
import { UserProductHistory } from "@app/Models/UserTransaction";
import { ProductItem } from '@app/Models/ProductItem';

@Component({
	selector: "app-myproducts",
	templateUrl: "./myproducts.component.html",
	styleUrls: ["./myproducts.component.sass"]
})
export class MyproductsComponent implements OnInit {
	constructor(
		private clientInfoService: ClientInstallInfoService,
		private transactionService: UserTransactionServiceService,
		private globals: Globals
	) {
		this.LoadProductInfo();
	}

	myProducts: MyProductInfo[];
	productUpdates: MyProductInfo[];
	productHistory: UserProductHistory[];
	updateAllUrl = environment.UiUrl + "?action=updateall";

	showHistory = false;
	historyLoaded = false;
	loadingMyProducts = true;
	loadingHistory = false;

	async LoadProductInfo() {
		if (this.globals.User != null) {
			this.myProducts = await this.clientInfoService
				.GetMyProductsList(this.globals.User.id)
				.then(value => {
					this.loadingMyProducts = false;
					return value;
				});

			this.productUpdates = this.clientInfoService.GetProductsWithUpdates(
				this.myProducts
			);
		} else {
			this.loadingMyProducts = false;
		}
	}

	async LoadProductHistory() {
		this.showHistory = !this.showHistory;

		if (!this.historyLoaded) {
			this.loadingHistory = true;

			if (this.globals.User != null) {
				this.productHistory = await this.transactionService
					.GetUserTransactionWithProductInfo(this.globals.User.id)
					.then(value => {
						this.loadingHistory = false;
						this.historyLoaded = true;
						return value;
					});
			} else {
				this.productHistory = null;
			}
		}
	}

	ngOnInit() {}

	areThereUpdates() {
		return (
			this.productUpdates !== undefined &&
			this.productUpdates !== null &&
			this.productUpdates.length > 0 &&
			this.globals.MachineName !== undefined &&
			this.globals.MachineName !== null &&
			this.globals.MachineName.length > 0
		);
	}

	areThereInstalledProducts() {
		return (
			this.myProducts !== undefined &&
			this.myProducts !== null &&
			this.myProducts.length > 0
		);
	}

	isUserLoggedin() {
		return this.globals.IsUserLoggedIn();
	}

	areThereHistoryRecords() {
		return (
			this.productHistory !== undefined &&
			this.productHistory !== null &&
			this.productHistory.length > 0
		);
	}

	showUpdateAllButton() {
		return parseInt(this.globals.ProVersion, 10) > 1704;
	}

	async sortUpdates(sort: Sort) {
		await this.sortUpdateData(sort);
	}

	async sortInstalled(sort: Sort) {
		await this.sortInstalledData(sort);
	}

	sortHistory(sort: Sort) {
		this.sortHistoryData(sort);
	}

	async sortUpdateData(sort: Sort) {
		const data = this.productUpdates.slice();
		if (!sort.active || sort.direction === "") {
			this.productUpdates = data;
			return;
		}

		this.productUpdates = data.sort((a, b) => {
			const isAsc = sort.direction === "asc";
			switch (sort.active) {
				case "name":
					return this.compare(
						a.product_info.product_name,
						b.product_info.product_name,
						isAsc
					);
				case "version":
					return this.compare(a.product_info.version, b.product_info.version, isAsc);
				case "product_type":
					return this.compare(
						a.product_info.item_type_id,
						b.product_info.item_type_id,
						isAsc
					);
				case "machine_name":
					return this.compare(a.machine_name, b.machine_name, isAsc);
				case "miproversion":
					return this.compare(a.mipro_version, b.mipro_version, isAsc);
				default:
					return 0;
			}
		});
	}

	async sortInstalledData(sort: Sort) {
		const data = this.myProducts.slice();
		if (!sort.active || sort.direction === "") {
			this.myProducts = data;
			return;
		}

		this.myProducts = data.sort((a, b) => {
			const isAsc = sort.direction === "asc";
			switch (sort.active) {
				case "name":
					return this.compare(
						a.product_info.product_name,
						b.product_info.product_name,
						isAsc
					);
				case "version":
					return this.compare(a.product_info.version, b.product_info.version, isAsc);
				case "product_type":
					return this.compare(
						a.product_info.item_type_id,
						b.product_info.item_type_id,
						isAsc
					);
				case "machine_name":
					return this.compare(a.machine_name, b.machine_name, isAsc);
				case "miproversion":
					return this.compare(a.mipro_version, b.mipro_version, isAsc);
				default:
					return 0;
			}
		});
	}

	async sortHistoryData(sort: Sort) {
		const data = this.productHistory.slice();
		if (!sort.active || sort.direction === "") {
			this.productHistory = data;
			return;
		}

		this.productHistory = data.sort((a, b) => {
			const isAsc = sort.direction === "asc";
			switch (sort.active) {
				case "name":
					return this.compare(
						a.productInfo.product_name,
						b.productInfo.product_name,
						isAsc
					);
				case "version":
					return this.compare(a.transaction.version, b.transaction.version, isAsc);
				case "product_type":
					return this.compare(
						a.productInfo.item_type_id,
						b.productInfo.item_type_id,
						isAsc
					);
				case "timestamp":
					return this.compare(
						a.transaction.timestamp,
						b.transaction.timestamp,
						isAsc
					);
				case "machine_name":
					return this.compare(
						a.transaction.device_name,
						b.transaction.device_name,
						isAsc
					);
				case "miproversion":
					return this.compare(
						a.transaction.mipro_version,
						b.transaction.mipro_version,
						isAsc
					);
				default:
					return 0;
			}
		});
	}

	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}
