import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Globals } from "@app/globals";

@Component({
	selector: "app-drag-drop-file-control",
	templateUrl: "./drag-drop-file-control.component.html",
	styleUrls: ["./drag-drop-file-control.component.css"]
})
export class DragDropFileControlComponent implements OnInit {
	@Output() messageToEmit = new EventEmitter<File>();
	@Input() thumbnailSize: boolean;
	@Input() file: File = null;
	@Input() fileType: String = null;
	@Input() dragDropMessage: String;
	@Input() dragDropFileTypeMessage: String;
	fileAdded = false;
	fileName: String = "";
	idInputControl;

	constructor(private translate: TranslateService, private globals: Globals) { }

	ngOnInit() {
		if (this.file != null) {
			this.fileAdded = true;
			this.fileName = this.file.name;
		} else {
			this.fileAdded = false;
			this.fileName = "";
		}
		this.idInputControl = this.createId();
	}

	createId() {
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var val = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			return (c == 'x' ? val : (val & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	}
	openFileBrowser($event: any, inputElement) {
		event.preventDefault();
		let element: HTMLElement = document.getElementById(inputElement.id);
		element.click();
	}
	customUpload(files: any) {
		for (const file of files) {
			if (this.fileType == file.type) {
				this.file = file;
				this.fileAdded = true;
				this.fileName = this.file.name;
				this.messageToEmit.emit(this.file);
			} else {
				this.globals.WindowAlertErrorMessage(this.translate.instant("DragNDropControl.invalid_file_selected"));
			}
		}
	}
	Cancel() {
		this.file = null;
		this.fileAdded = false;
		this.fileName = "";
		this.messageToEmit.emit(null);
	}

	onDrop(event) {
		event.preventDefault();
		event.stopPropagation();
		if (event.dataTransfer.files[0]) {
			this.customUpload(event.dataTransfer.files);
		}
	}
	onDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
	}
	onDragEnter(event) {
		event.preventDefault();
		event.stopPropagation();
	}
}
