import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-page-not-found",
	templateUrl: "./page-not-found.component.html",
	styleUrls: ["./page-not-found.component.sass"]
})
export class PageNotFoundComponent implements OnInit {
	errorText: string;

	constructor() {
		this.errorText = "There was an error";
	}

	ngOnInit() {}
}
