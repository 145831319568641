import { Injectable } from "@angular/core";
import { ProductItem } from "@app/Models/ProductItem";
import { environment } from "@env/environment";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "@app/globals";

@Injectable({
	providedIn: "root",
})
export class ProductSearchService {
	constructor(private http: HttpClient, private globals: Globals) {}

	async getProductList(searchTerm: string): Promise<ProductItem[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.ProductSearchEndpoint;
		if (searchTerm != null && searchTerm !== undefined) {
			urlValue = this.globals.AddParamToUrl(urlValue, "keyword", searchTerm);
		}

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);
		// Not return resule from cache for searching.
		let x: any = await this.http
			.get<ApiResponse>(urlValue, {})
			.toPromise()
			.catch((err: HttpErrorResponse) => {
				this.globals.HttpErrorResponseHandling(err.status);
			});
		return JSON.parse(x.body);
	}
}
