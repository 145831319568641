<div class="container">
	<div class="main-header">
		<h1 translate [translateParams]="{searchTxt: searchText}">search.search_title</h1>
	</div>
	<h3 *ngIf="searchResults.length <= 0 && waitingFlag == false" translate>search.no_results</h3>
	<h3 *ngIf="searchResults.length <= 0 && waitingFlag == true" translate>search.getting_results</h3>
	<div *ngIf="searchResults.length > 0" class="row">
		<div class="col">
			<ul class="list-group">
				<li class="result list-group-item" *ngFor="let p of searchResults; let i = index"
					[routerLink]="['/product/', p.product_id]">
					<table>
						<tr>
							<th rowspan="2">
								<img src="{{ p.icon_url }}" style="height:96px;-ms-interpolation-mode:bicubic;">
							</th>
							<th>
								<H1 class="m-0 ml-3 p-0">{{ p.product_name }}</H1>
							</th>
						</tr>
						<tr>
							<th>
								<H3 class="mb-2 mt-0 ml-4">{{p.author}}</H3>
							</th>
						</tr>
					</table>
				</li>
			</ul>
		</div>
	</div>
</div>