export class ProductItem {
	product_short_description: string;
	product_pro_supported_version_from: string;
	item_type_id: string;
	version: string;
	product_name: string;
	product_publisher_user_id: string;
	product_long_description: string;
	modified_datetime_stamp: Date;
	created_datetime_stamp: Date;
	product_id: string;
	product_isfeatured: string;
	icon_url: string;
	whats_new: string;
	documentation_url: string;
	release_url: string;
	legal_url: string;
	snapshots_urls?: (string)[] | null;
	latest_version?: (string)[] | null;
	product_category_tags_id?: (string)[] | null;
	author?: string;
	email?: string;
	website?: string;
	deleted: boolean;
	platform: string;
}
export class OtherLanguageInfo
{
	product_name: string;
	product_short_description : string;
	product_long_description: string;
	whats_new : string;  
  	language : string;
}