import { Component, OnInit } from "@angular/core";
import { ProductDownloadService } from "@app/Services/product-download.service";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-install",
	template: "",
	styleUrls: ["./install.component.sass"]
})
export class InstallComponent implements OnInit {
	productId: string;

	constructor(
		private route: ActivatedRoute,
		private downloadUrlService: ProductDownloadService
	) {
		this.getFile();
	}

	ngOnInit() {}

	async getFile() {
		this.productId = this.route.snapshot.paramMap.get("id");
		const productUrl = await this.downloadUrlService.getProductDownloadurl(
			this.productId
		);

		window.location.href = productUrl;

		// var a = document.createElement("a");

		// a.href = this.globals.AddParamToUrl(productUrl, "product_id", this.productId);
		// a.download = "product.zip";
		// var e = document.createEvent("MouseEvents");
		// // Use of deprecated function to satisfy TypeScript.
		// e.initMouseEvent("click", true, false,
		//   document.defaultView, 0, 0, 0, 0, 0,
		//   false, false, false, false, 0, null);
		// a.dispatchEvent(e);
		// a.remove();
	}
}
