import { environment as defaultEnvironment } from "./environment.defaults";

export const environment = {
	...defaultEnvironment,
	production: true,
	ApiUrl: "https://p9aic9zc0f.execute-api.us-east-1.amazonaws.com/prod/appstore",
	UiUrl: "https://pb-mapinfo-appstore-prod.s3.amazonaws.com",
	EnvironmentLabel: "",
	AdminAvailable: false,
};
