import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "@env/environment";
import { Category } from "@app/Models/Category";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { Globals } from "@app/globals";

@Injectable({
	providedIn: "root"
})
export class CategoryService {
	constructor(private http: HttpClient, private globals: Globals) {}

	async getCategoryList(): Promise<Category[]> {
		let urlValue =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.CategoryListEndpoint;

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);

		let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
			(err: HttpErrorResponse) => {
			  this.globals.HttpErrorResponseHandling(err.status);
		  });

		return JSON.parse(x.body);
	}

	async getCategoryById(categoryId: string): Promise<Category> {
		let urlValue: string =
			environment.ApiUrl +
			environment.ApiVersion +
			environment.CategoryListEndpoint;

		urlValue = this.globals.AddParamToUrl(urlValue, "category_id", categoryId);
		urlValue = this.globals.AddLocaleParamToUrl(urlValue);

		let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
			(err: HttpErrorResponse) => {
			  this.globals.HttpErrorResponseHandling(err.status);
		  });

		if (x.body !== "Category not found") {
			return JSON.parse(x.body)[0];
		} else {
			const noCat: Category = new Category();

			noCat.category_name = "No Category Found";

			return noCat;
		}
	}
}
