<div class="page-header">
	<div class="container">
		<div class="row">
			<div class="col">
				<nav aria-label="breadcrumb" class="breadcrumbs">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a [routerLink]="['/']" translate>product_page.home</a>
						</li>
						<li class="breadcrumb-item">
							<a [routerLink]="['/']" translate>product_page.products_page</a>
						</li>
						<li class="breadcrumb-item active" aria-current="page">
							{{ productItem.product_name }}
						</li>
					</ol>
				</nav>
			</div>
		</div>
	</div>
</div>
<div class="container needs-validation was-validated" novalidate>
	<div class="row">
		<div class="col-md-3 container mx-1" role="main">
			<div class="form-group row justify-content-center">
				<div
					class="col profile-pic"
					style="cursor: pointer; max-width: 192px; max-height: 192px"
				>
					<img
						class="img-thumbnail app_icon"
						[src]="productIconSrc"
						*ngIf="productIconSrc"
						data-toggle="modal"
						data-target="#thumbnailDialogModal"
					/>
					<div
						class="edit_icon"
						data-toggle="modal"
						data-target="#thumbnailDialogModal"
					>
						<div
							class="pbi-icon-mini pbi-pencil x2"
							style="background-color: transparent"
						></div>
					</div>
					<!-- Modal -->
					<div
						class="modal fade"
						id="thumbnailDialogModal"
						tabindex="-1"
						role="dialog"
						aria-labelledby="thumbnailDialogModalLabel"
						aria-hidden="true"
						data-backdrop="static"
					>
						<div class="modal-dialog" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="thumbnailDialogModalLabel" translate>
										EditProduct.UdateAppIconDialogTitle
									</h5>
								</div>
								<div class="modal-body">
									<div class="row">
										<div class="form-row md-2">
											<div class="column">
												<div>
													<img
														class="img-thumbnail"
														[src]="productIconSrc"
														*ngIf="productIconSrc"
														style="
															max-height: 192px;
															max-width: 192px;
															width: auto;
															height: auto;
															margin: auto;
															display: block;
														"
													/>
												</div>
											</div>

											<div class="column" style="margin-left: 5px">
												<app-drag-drop-file-control
													[dragDropMessage]="dragDropMessage"
													[dragDropFileTypeMessage]="dragDropFileTypeMessage"
													[fileType]="dragDropfileType"
													[file]="productIconFile"
													[thumbnailSize]="true"
													(messageToEmit)="getFileFromControl($event)"
												>
												</app-drag-drop-file-control>
											</div>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button
										float-right
										type="button"
										class="btn btn-primary"
										data-dismiss="modal"
										style="margin: auto; display: block"
										translate
									>
										EditProduct.Ok
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<button
						class="btn btn-primary btn-block"
						matRipple
						type="button"
						[disabled]="
							(startUploading && !endUploading) ||
							(productItem.product_name === '' && language === 'en') ||
							language === ''
						"
						(click)="save()"
						translate
					>
						EditProduct.Save
					</button>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<button
						href="#"
						[routerLink]="['/product/', productItem.product_id]"
						class="overlay-text btn btn-secondary btn-block"
						matRipple
						type="button"
						[disabled]="startUploading && !endUploading"
						translate
					>
						EditProduct.Cancel
					</button>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-publisher" translate>AddProductStep2.Publisher </label>
					<input
						class="form-control"
						id="id-input-publisher"
						[(ngModel)]="productItem.author"
						#product_name="ngModel"
						name="product_publisher_user_id"
						type="text"
						readonly
					/>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-publisher" translate>product_page.product_type </label>
					<input
						class="form-control"
						id="id-input-item_type_id"
						[(ngModel)]="productItem.item_type_id"
						#item_type_id="ngModel"
						name="item_type_id"
						type="text"
						readonly
					/>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-miproVer" translate>AddProductStep2.MIPROVersion </label>
					<select
						class="form-control"
						id="id-miproVer"
						[(ngModel)]="productItem.product_pro_supported_version_from"
						#product_pro_supported_version_from="ngModel"
						name="product_pro_supported_version_from"
					>
						<option
							*ngFor="let miproVersion of miproVerions"
							[value]="miproVersion.major_version"
						>
							{{ miproVersion.display_name }}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-website" translate>EditProduct.WebSite </label>
					<input
						class="form-control"
						id="id-website"
						[(ngModel)]="productItem.website"
						#website="ngModel"
						name="website"
						type="text"
						readonly
					/>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-categories" translate>AddProductStep2.Categories</label>
					<ng-select
						id="id-categories"
						[items]="categories"
						[multiple]="true"
						bindLabel="category_name"
						[selectableGroup]="true"
						[closeOnSelect]="true"
						bindValue="category_id"
						[(ngModel)]="productItem.product_category_tags_id"
						#product_category_tags_id="ngModel"
						name="product_category_tags_id"
					>
					</ng-select>
				</div>
			</div>
			<div class="form-group row">
				<div class="col">
					<label for="id-categories" translate>header.platform_link</label>
					<select
						class="form-control"
						id="id-platform"
						[(ngModel)]="productItem.platform"
						#languageText="ngModel"
						name="language"
					>
						<option value=""></option>
						<option *ngFor="let item of platform" [value]="item.id" translate>
							{{ item.display_name }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="col-md-7 mx-1">
			<div class="container needs-validation was-validated" novalidate>
				<div class="row">
					<div class="col">
						<label translate class="h3 mt-1 mb-2">EditProduct.Description</label>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group contianer">
							<div class="row">
								<div class="col">
									<select class="form-control" id="id-languageDropdown"
                                        [(ngModel)]="language" [disabled]="!loadingLanguage" autofocus
                                        #languageText name="language"
										(change)="changeLanguage(languageText)" required
									>
                                        <option *ngFor="let item of selectedLanguages" [value]="item" translate>
                                            selectedLanguageName.{{item}}
                                        </option>
                                    </select>
								</div>
								<div class="col-md-auto">
									<button
										class="btn btn-secondary"
										matRipple
										type="button"
										(click)="clearLanguage()"
										translate
									>
										AddProductStep2.ClearLanguage
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="id-product-name" translate
								>AddProductStep2.ProductName
								<span class="required" *ngIf="language === 'en'" translate
									>AddProductStep2.Required</span
								>
							</label>
							<input
								class="form-control"
								*ngIf="language === 'en'"
								id="id-product-name"
								[(ngModel)]="productItem.product_name"
								#product_name="ngModel"
								name="product_name"
								(change)="updateOtherLanguageInfo()"
								type="text"
								required
							/>
							<input
								class="form-control"
								*ngIf="language !== 'en'"
								id="id-product-name"
								[(ngModel)]="productItem.product_name"
								#product_name="ngModel"
								name="product_name"
								(change)="updateOtherLanguageInfo()"
								type="text"
							/>

							<div class="invalid-feedback" translate *ngIf="language === 'en'">
								AddProductStep2.ProductNameRequired
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="id-long-desc" translate>AddProductStep2.ProductDesc</label>
							<textarea
								name="counterTest"
								id="id-long-desc"
								class="form-control"
								(change)="updateOtherLanguageInfo()"
								placeholder="{{
									'AddProductStep2.LongDescriptionPlaceholder' | translate
								}}"
								[(ngModel)]="productItem.product_long_description"
								#product_long_description="ngModel"
								name="product_long_description"
								maxlength="{this.globals.descriptionCharLimit}"
								rows="5"
							></textarea>
							<div class="text-area-counter">
								{{
									productDescAreaMaxChar - productItem.product_long_description.length
								}}
								characters remaining
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="id-short-desc" translate>AddProductStep2.ShortDesc</label>
							<textarea
								name="counterTest"
								id="id-short-desc"
								class="form-control"
								(change)="updateOtherLanguageInfo()"
								placeholder="{{
									'AddProductStep2.ShortDescriptionPlaceholder' | translate
								}}"
								[(ngModel)]="productItem.product_short_description"
								#product_short_description="ngModel"
								name="product_short_description"
								maxlength="255"
								rows="5"
							></textarea>
							<div class="text-area-counter">
								{{
									shortDescAreaMaxChar - productItem.product_short_description.length
								}}
								characters remaining
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="id-counterTest" translate>AddProductStep2.WhatsNew</label>
							<textarea
								name="counterTest"
								id="id-counterTest"
								class="form-control"
								(change)="updateOtherLanguageInfo()"
								placeholder="{{ 'AddProductStep2.WhatsNewPlaceholder' | translate }}"
								[(ngModel)]="productItem.whats_new"
								#product_long_description="ngModel"
								name="whats_new"
								maxlength="{this.globals.descriptionCharLimit}"
								rows="5"
							></textarea>
							<div class="text-area-counter">
								{{ whatsNewAreaMaxChar - productItem.whats_new.length }} characters
								remaining
							</div>
						</div>
					</div>
				</div>

				<!-- screenshot -->
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label translate>AddProductStep3.ScreenshotHeading1</label>
							<app-files-upload-preview
								[editSnapshotsFiles]="snapshotsEdit"
								[files]=""
								[isEdit]="isEditSnapshot"
								(messageToEmit)="getSnapshotsFileFromControl($event)"
							>
							</app-files-upload-preview>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group container">
							<div class="row">
								<div class="form-group col-md-8 container">
									<div class="row">
										<div class="col">
											<label for="id-aboutProduct" class="h3" translate
												>EditProduct.AboutProduct</label
											>
										</div>
									</div>
									<div class="row">
										<div class="form-group col container">
											<div class="row align-items-center">
												<div class="col-md-auto">
													<label for="id-VersionNumberLabel" translate class="h5 my-1 mr-1"
														>product_page.version</label
													>
													<label for="id-VersionNumber" class="h5 my-1" translate>{{
														productItem.version
													}}</label>
												</div>
												<div class="col">
													<select
														class="form-control"
														id="id-previousVersion"
														[(ngModel)]="selectedPreviousVersion"
														#previousVersion="ngModel"
														name="previousVersion"
														(change)="getSelectedPreviousVersionVal()"
													>
														<option
															*ngFor="let addinVersion of addinVersions"
															[value]="addinVersion.value"
														>
															{{ addinVersion.displayName }}
														</option>
													</select>
												</div>
											</div>
											<div class="row align-items-end mt-3">
												<div class="col">
													<label style="margin-right: 10px" translate
														>EditProduct.VersionFile</label
													>
													<input
														type="text"
														class="form-control"
														readonly
														value="{{ versionFileName }}"
													/>
													<span
														name="deleteProductFileIcon"
														*ngIf="versionFileName !== ''"
														class="pbi-icon-mini pbi-delete-circle"
														title="{{ deleteToolTip }}"
														style="cursor: pointer; color: blue"
														(click)="productFileDelete()"
													></span>
												</div>
												<div class="col-auto align-self-end">
													<button
														onclick="document.getElementById('versionFileId').click();"
														class="btn btn-secondary"
														matRipple
														type="button"
														translate
													>
														EditProduct.UpdateVersionFile
													</button>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="file"
														id="versionFileId"
														name="upload"
														style="visibility: hidden; width: 1px; height: 1px"
														(change)="updateVersionFile($event.target.files)"
														onclick="this.value=null;"
														accept="application/x-zip-compressed"
														translate
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group col-md-4 container">
									<div class="row">
										<div class="col">
											<label for="id-downloads" class="h3" translate
												>EditProduct.Downloads</label
											>
										</div>
									</div>

									<div class="row mt-1">
										<div class="col container">
											<div class="row">
												<div class="col">
													<label for="id-documentation" translate
														><b translate>EditProduct.Documentation</b></label
													>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="text"
														class="form-control"
														readonly
														value="{{ documentationFilePath }}"
													/>
												</div>
											</div>
											<div class="row my-2">
												<div class="col container">
													<div class="row">
														<div class="col-auto">
															<span
																name="deleteDocIcon"
																class="pbi-icon-mini pbi-delete-circle"
																title="{{ deleteToolTip }}"
																style="cursor: pointer; color: blue"
																(click)="docDelete()"
															></span>
														</div>
														<div class="col-auto">
															<span
																name="undoDocIcon"
																style="cursor: pointer"
																class="pbi-icon-mini pbi-undo"
																title="{{ undoToolTip }}"
																style="color: blue; cursor: pointer"
																(click)="undoDocClicked()"
															></span>
														</div>
														<div class="col-auto">
															<a
																style="cursor: pointer"
																onclick="document.getElementById('id-doc_upload').click()"
															>
																<span
																	name="uploadDocIcon"
																	title="{{ uploadToolTip }}"
																	class="pbi-icon-mini pbi-upload"
																	style="color: blue"
																></span
															></a>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="file"
														#inputElement
														id="id-doc_upload"
														translate
														name="release_doc_upload"
														style="visibility: hidden; width: 1px; height: 1px"
														(change)="docUpload($event.target.files)"
														onclick="this.value=null;"
														class="hideFileUpload"
														accept="application/pdf"
													/>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col container">
											<div class="row">
												<div class="col">
													<label
														for="id-release_notes"
														translate
														title="{{ release_notesPath }}"
														><b translate>EditProduct.ReleaseNotes</b></label
													>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="text"
														class="form-control"
														readonly
														value="{{ release_notesPath }}"
													/>
												</div>
											</div>
											<div class="row my-2">
												<div class="col-auto">
													<span
														name="deleteReleaseNotesIcon"
														title="{{ deleteToolTip }}"
														class="pbi-icon-mini pbi-delete-circle"
														style="cursor: pointer; color: blue"
														(click)="releaseNotesDelete()"
													></span>
												</div>
												<div class="col-auto">
													<span
														name="undoReleaseNotesIcon"
														class="pbi-icon-mini pbi-undo"
														style="cursor: pointer; color: blue; cursor: pointer"
														title="{{ undoToolTip }}"
														(click)="undoReleseNotesClicked()"
													></span>
												</div>
												<div class="col text-left">
													<a
														style="cursor: pointer"
														onclick="document.getElementById('id-release_doc_upload').click()"
													>
														<span
															name="uploadReleaseNotesIcon"
															class="pbi-icon-mini pbi-upload"
															title="{{ uploadToolTip }}"
															style="color: blue"
														></span
													></a>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="file"
														#inputElement
														id="id-release_doc_upload"
														translate
														name="release_doc_upload"
														style="visibility: hidden; width: 1px; height: 1px"
														(change)="releaseNotesUpload($event.target.files)"
														onclick="this.value=null;"
														class="hideFileUpload"
														accept="application/pdf"
													/>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col container">
											<div class="row">
												<div class="col">
													<label for="id-legal" translate
														><b translate>EditProduct.Legal</b></label
													>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="text"
														class="form-control"
														readonly
														value="{{ legalFilePath }}"
													/>
												</div>
											</div>
											<div class="row my-2">
												<div class="col-auto">
													<span
														name="deleteLegalDocIcon"
														title="{{ deleteToolTip }}"
														class="pbi-icon-mini pbi-delete-circle"
														style="cursor: pointer; color: blue"
														(click)="legalDelete()"
													></span>
												</div>
												<div class="col-auto">
													<span
														name="undoLegalDocIcon"
														class="pbi-icon-mini pbi-undo"
														title="{{ undoToolTip }}"
														style="cursor: pointer; color: blue; cursor: pointer"
														(click)="undoLegalClicked()"
													></span>
												</div>
												<div class="col-auto">
													<a
														style="cursor: pointer"
														onclick="document.getElementById('id-legal_upload').click()"
													>
														<span
															name="uploadLegalDocIcon"
															title="{{ uploadToolTip }}"
															class="pbi-icon-mini pbi-upload"
															style="color: blue"
														></span
													></a>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<input
														type="file"
														#inputElement
														id="id-legal_upload"
														translate
														name="legaldoc_upload"
														style="visibility: hidden; width: 1px; height: 1px"
														(change)="legalDocUpload($event.target.files)"
														onclick="this.value=null;"
														class="hideFileUpload"
														accept="application/pdf"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
