import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	OnDestroy,
} from "@angular/core";
import { Category, Platform } from "@app/Models/Category";
import { CategoryService } from "@app/Services/category.service";
import { ProductItem, OtherLanguageInfo } from "@app/Models/ProductItem";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "@app/globals";
import { MapInfoVersion} from "@app/Models/MapInfoVersion";
import { MiproVersionService } from "@app/Services/mipro-version.service";
// TODO - will be place at appropriate place, when
// its values are fetched from database.
// displayName mapped to author
// value mapped to product_publisher_user_id
interface Publishers {
	displayName: string;
	value: string;
}

@Component({
	selector: "app-product-add-step2",
	templateUrl: "./product-add-step2.component.html",
	styleUrls: ["./product-add-step2.component.sass"],
})
export class ProductAddStep2Component implements OnInit, OnDestroy {
	@Output() messageToEmit = new EventEmitter<File>();
	@Input() productItem: ProductItem;
	@Input() otherLanguageInfo: Map<string, OtherLanguageInfo>;
	@Input() productIconFile: File;
	@Input() language: any;
	shortDescAreaMaxChar = 255;
	productDescAreaMaxChar = this.globals.descriptionCharLimit;
	whatsNewAreaMaxChar = this.globals.descriptionCharLimit;
	categories: Category[];
	platform: Platform[];
	productIconSrc: any;
	dragDropfileType: String;
	dragDropMessage: string;
	dragDropFileTypeMessage: string;
	languageAddedToolTip: string;
	languageAddedText: string;
	appVersionPattern: string;
	selectedLanguages = this.globals.supportedLanguageCodes;
	miproVerions: MapInfoVersion[] = [];
	// publisher list - TODO - should be fetched from database
	publisherList: Publishers[] = [
		{ displayName: "", value: "No Publisher" },
		{
			displayName: "Precisly",
			value: "Precisely,abc@precisely.com,https://www.precisely.com/",
		},
		{
			displayName: "Publisher1",
			value: "Publisher1,publisher1@precisely.com,https://www.precisely.com/",
		},
		{
			displayName: "Publisher2",
			value: "Publisher2,publisher2@precisely.com,https://www.precisely.com/",
		},
	];

	constructor(
		private categoryService: CategoryService,
		private translate: TranslateService,
		private globals: Globals,
		private miproVersionService: MiproVersionService,
	) {
		this.getCategorylist();
		this.getMiproVersion();
		this.platform = this.globals.platform;
	}

	ngOnInit() {
		this.appVersionPattern = this.globals.appVersionPattern;
		this.showIconImage(this.productIconFile);
		this.dragDropfileType = "image/png";
		this.dragDropMessage = this.translate.instant(
			"DragNDropControl.DragnDropAppIcon"
		);
		this.dragDropFileTypeMessage = this.translate.instant(
			"DragNDropControl.fileTypePng"
		);
		this.setLanguageToolTip();
		// If publisher not present in list, add it. This is when page is open
		// for addVersion
		if (
			this.productItem.author != null &&
			this.productItem.author !== undefined &&
			this.productItem.author.trim() !== ""
		) {
			this.publisherList.push({
				displayName: this.productItem.author,
				value: this.productItem.product_publisher_user_id,
			});
		}
		this.updateOtherLanguageInfo();
	}

	ngOnDestroy() {}
	async getMiproVersion(){

		this.miproVerions = (await this.miproVersionService.GetMiproVersionList()).sort(
			function (verA, verB) {
				if (verA.display_name < verB.display_name) {
					return -1;
				}
				if (verA.display_name > verB.display_name) {
					return 1;
				}
				return 0;
			}
		);
		const result = this.miproVerions.find(
			({ major_version }) => major_version === this.productItem.product_pro_supported_version_from
		);
		if (result == null || result === undefined) {
			this.miproVerions.push({
				version:this.productItem.product_pro_supported_version_from,
				display_name:this.productItem.product_pro_supported_version_from,
				major_version:this.productItem.product_pro_supported_version_from
			})
		}
		
	};
	async getCategorylist() {
		this.categories = (await this.categoryService.getCategoryList()).sort(
			function (catA, catB) {
				if (catA.category_name < catB.category_name) {
					return -1;
				}
				if (catA.category_name > catB.category_name) {
					return 1;
				}
				return 0;
			}
		);
	}
	setLanguageToolTip() {
		this.languageAddedText =
			"(" +
			this.otherLanguageInfo.size +
			" " +
			this.translate.instant("AddProductStep2.Languageadded") +
			")";
		this.languageAddedToolTip =
			this.otherLanguageInfo.size +
			" " +
			this.translate.instant("AddProductStep2.Languageadded") +
			"\n";

		this.otherLanguageInfo.forEach((value: OtherLanguageInfo, key: string) => {
			this.languageAddedToolTip += this.globals.getLanguageFromLocaleCode(key);
			this.languageAddedToolTip += "\n";
		});
	}
	clearLanguage() {
		if (this.otherLanguageInfo.has(this.language.str)) {
			this.otherLanguageInfo.delete(this.language.str);
		}
		this.productItem.product_name = "";
		this.productItem.product_long_description = "";
		this.productItem.product_short_description = "";
		this.productItem.whats_new = "";
		this.setLanguageToolTip();
	}
	updateOtherLanguageInfo() {
		if (this.otherLanguageInfo.has(this.language.str)) {
			const value = this.otherLanguageInfo.get(this.language.str);
			value.product_name = this.productItem.product_name;
			value.product_long_description = this.productItem.product_long_description;
			value.product_short_description = this.productItem.product_short_description;
			value.whats_new = this.productItem.whats_new;
			this.otherLanguageInfo.set(this.language.str, value);
		} else {
			const languageInfo = new OtherLanguageInfo();
			languageInfo.product_name = this.productItem.product_name;
			languageInfo.language = this.language.str;
			languageInfo.product_long_description = this.productItem.product_long_description;
			languageInfo.product_short_description = this.productItem.product_short_description;
			languageInfo.whats_new = this.productItem.whats_new;
			this.otherLanguageInfo.set(this.language.str, languageInfo);
			this.setLanguageToolTip();
		}
	}
	changeLanguage() {
		if (this.otherLanguageInfo.has(this.language.str)) {
			const value = this.otherLanguageInfo.get(this.language.str);
			this.productItem.product_long_description = value.product_long_description;
			this.productItem.product_name = value.product_name;
			this.productItem.product_short_description = value.product_short_description;
			this.productItem.whats_new = value.whats_new;
		} else {
			this.productItem.product_name = "";
			this.productItem.product_long_description = "";
			this.productItem.product_short_description = "";
			this.productItem.whats_new = "";
		}
	}
	showIconImage(file: File) {
		if (file != null) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (_event) => {
				this.productIconSrc = reader.result;
			};
		}
	}
	getFileFromControl(inFile: File) {
		if (inFile != null) {
			this.productIconFile = inFile;
			this.showIconImage(this.productIconFile);
			// emit to Main AddPage
			this.messageToEmit.emit(this.productIconFile);
		} else {
			this.productIconSrc = null;
			this.productIconFile = null;
			// emit to Main AddPage
			this.messageToEmit.emit(null);
		}
	}
}
