<div
	class="container mb-5"
	*ngIf="selectedProduct !== undefined && selectedProduct !== null"
>
	<div class="row">
		<div class="col">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']" translate>product_page.home</a>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/']" translate>product_page.products_page</a>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						{{ selectedProduct.product_name }}
					</li>
				</ol>
			</nav>
		</div>
	</div>

	<div class="row justify-content-center align-items-center">
		<div class="col">
			<table>
				<tr>
					<th rowspan="2">
						<img
							src="{{ selectedProduct.icon_url }}"
							style="height: 96px; -ms-interpolation-mode: bicubic"
						/>
					</th>
					<th>
						<H1 class="m-0 ml-3 p-0">{{ selectedProduct.product_name }}</H1>
					</th>
				</tr>
				<tr>
					<th>
						<H3 class="mb-2 mt-0 ml-4"
							><a
								href="mailto:{{ selectedProduct.email }}"
								data-toggle="tooltip"
								title="mailto:{{ selectedProduct.email }}"
								>{{ selectedProduct.author }}</a
							></H3
						>
					</th>
				</tr>
			</table>
		</div>

		<div class="col-auto mt-3">
			<!--For Testing-->
			<button
				*ngIf="showAdminControls"
				class="btn btn-primary shadow"
				(click)="navigateToEditProduct()"
				style="margin-right: 3px"
				[disabled]="!productLoaded"
			>
				Edit Product
			</button>

			<!--TODO will be repalced at appropriate place-->
			<button
				*ngIf="showAdminControls"
				class="btn btn-primary shadow"
				style="margin-right: 3px"
				data-toggle="modal"
				data-target="#DeleteWarningDialogModal"
				[disabled]="!productLoaded"
				translate
			>
				product_page.delete_product
			</button>
			<button
				*ngIf="showAdminControls"
				class="btn btn-primary shadow"
				style="margin-right: 3px"
				(click)="addNewVersion()"
				[disabled]="!productLoaded"
				translate
			>
				product_page.add_version
			</button>

			<button
				*ngIf="showAdminControls"
				class="btn btn-primary shadow"
				style="margin-right: 3px"
				data-toggle="modal"
				data-target="#manageVersionDialogModal"
				[disabled]="!productLoaded || this.manageVersionTableRows.length === 0"
				translate
			>
				{{ manage_version }}
			</button>

		 <a
				href="{{ selectedProductUrl.presignedURL }}"
				style="color: white"
				[ngClass]="{ disabled: !productLoaded }"
			>
			<button
			  class="btn btn-primary shadow"
				 style="margin-right: 3px; color: white"
				 download
				 *ngIf="!noVersionForPro && selectedProduct.item_type_id !== 'SourceCode'"
				 [disabled]="!productLoaded"
				 translate
			 >
				<i
						class="pbi-icon-mini pbi-download align-bottom"
						style="color: white"
						aria-hidden="true"
					></i
					>{{ buttonText }}
				</button>
			</a>
			
			 <a
					href="{{ selectedProductUrl.presignedURL }}"
					style="color: white"
					target="_blank"
					[ngClass]="{ disabled: !productLoaded }"
					translate
				>
			 <button
					 class="btn btn-primary shadow"
				  style="margin-right: 3px; color: white"
				  *ngIf="!noVersionForPro && selectedProduct.item_type_id === 'SourceCode'"
				  [disabled]="!productLoaded"
				>
				<i
						class="pbi-icon-mini pbi-link align-bottom"
						style="color: white"
						aria-hidden="true"
					>
					</i>{{ buttonTextGoToProductPage }}
					</button>
			 </a>
			<!-- a
				class="btn btn-disabled shadow"
				style="margin-right: 3px"
				translate
				*ngIf="noVersionForPro"
				translate
			>
				<i class="pbi-icon-mini pbi-download align-bottom" aria-hidden="true"></i
				>{{ buttonText }}
			</a n-->
		</div>

		<div
			class="modal fade"
			id="manageVersionDialogModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="manageVersionDialogModalLabel"
			aria-hidden="true"
			data-backdrop="static"
		>
			<div class="modal-dialog" role="document">
				<div class="modal-content" style="width: 40em">
					<div class="modal-header">
						<h5 class="modal-title" id="manageVersionDialogModalLabel" translate>
							product_page.previous_versions
						</h5>
					</div>
					<div class="modal-body">
						<div *ngIf="noRecordMsgFlag == false">
							<!-- PRIMENG TABLE -->
							<p-table
								[columns]="manageVersionTableColsHeader"
								[value]="manageVersionTableRows"
								dataKey="version"
								rowExpandMode="single"
								[(selection)]="selectedVersions"
							>
								<ng-template pTemplate="header" let-columns>
									<tr>
										<th style="width: 10em" scope="col"></th>
										<th
											class="nowrap"
											*ngFor="let col of columns"
											scope="col"
											[pSortableColumn]="col.field"
										>
											{{ col.header }}
											<p-sortIcon [field]="col.field"></p-sortIcon>
										</th>
										<th>
											<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
										</th>
									</tr>
								</ng-template>
								<ng-template
									pTemplate="body"
									let-rowData
									let-expanded="expanded"
									let-columns="columns"
								>
									<tr [pSelectableRow]="rowData">
										<td>
											<a
												href="#"
												[pRowToggler]="rowData"
												class="table-row-toggle"
												[attr.aria-label]="expanded ? 'Collapse row' : 'Expand row'"
											>
												<i
													[ngClass]="
														expanded
															? 'pbi-icon-mini pbi-minimal-down'
															: 'pbi-icon-mini pbi-minimal-right'
													"
													aria-hidden="true"
												></i>
											</a>
										</td>
										<td *ngFor="let col of columns">{{ rowData[col.field] }}</td>
										<td>
											<p-tableCheckbox
												[value]="rowData"
												aria-label="Select row"
											></p-tableCheckbox>
										</td>
									</tr>
								</ng-template>
								<ng-template
									pTemplate="rowexpansion"
									let-rowData
									let-columns="columns"
									let-i="rowIndex"
								>
									<tr class="table-details" aria-live="polite" role="alert">
										<td [attr.colspan]="columns.length + 1">
											<p class="sr-only">Row {{ i + 1 }} details</p>
											<div class="table-details-content">
												<table class="table table-unstyled mb-0">
													<tbody>
														<tr>
															<div class="row">
																<div class="col">
																	<b>What's New</b>
																</div>
																<div class="col">
																	{{ rowData["whatsnew"] }}
																</div>
															</div>
															<div class="row">
																<br />
															</div>
															<div class="row">
																<div class="col">
																	<b>Description</b>
																</div>
																<div class="col">
																	{{ rowData["short_decs"] }}
																</div>
															</div>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
						<div *ngIf="noRecordMsgFlag == true">
							<p
								style="
									border: 1px solid red;
									text-align: center;
									background-color: rgb(209, 197, 197);
								"
							>
								No record found
							</p>
						</div>
					</div>
					<div class="modal-footer">
						<button
							float-right
							type="button"
							class="btn btn-primary"
							data-dismiss="modal"
							style="margin: auto; display: block"
							translate
						>
							product_page.cancel
						</button>

						<button
							float-right
							type="button"
							class="btn btn-secondary"
							(click)="manageVersionDialogDelete()"
							data-dismiss="modal"
							[disabled]="!selectedVersions.length"
							style="margin: auto; display: block"
							translate
						>
							product_page.delete
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal"
			id="DeleteWarningDialogModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="DeleteWarningDialogModalLabel"
			aria-hidden="true"
			data-backdrop="static"
		>
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="DeleteWarningDialogModalLabel" translate>
							{{ deleteProductWarningDialogTitle }} {{ selectedProduct.product_name }}
							{{ selectedProduct.version }}
						</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<mat-icon style="color: orange">warning</mat-icon>
							<div translate>
								{{ deleteProductWarningDialogMessage }}
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							class="btn btn-primary"
							data-dismiss="modal"
							style="margin: auto; display: block"
							translate
							(click)="DeleteProduct()"
						>
							{{ delete }}
						</button>
						<button
							class="btn btn-primary"
							data-dismiss="modal"
							style="margin: auto; display: block"
							translate
						>
							{{ cancel }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row my-3">
		<div class="col-sm-4 mx-3 justify-content-center">
			<div class="row">
				<div
					id="screenshotCarousel"
					class="carousel slide w-100"
					data-ride="carousel"
					data-pause="hover"
				>
					<div
						class="carousel-inner"
						*ngIf="selectedProduct !== undefined && selectedProduct !== null"
					>
						<div
							class="carousel-item"
							[ngClass]="{ active: i == 0 }"
							*ngFor="let screenshot of selectedProduct.snapshots_urls; let i = index"
						>
							<img
								src="{{ screenshot }}"
								(click)="openModal(); currentSlide(i)"
								class="d-block w-100"
								alt="..."
							/>
						</div>
					</div>
					<a class="" href="#screenshotCarousel" role="button" data-slide="prev">
						<span class="prev" aria-hidden="true">&#10094;</span>
						<span class="sr-only" translate>product_page.previous</span>
					</a>
					<a class="" href="#screenshotCarousel" role="button" data-slide="next">
						<span class="next" aria-hidden="true">&#10095;</span>
						<span class="sr-only" translate>product_page.next</span>
					</a>
				</div>
			</div>

			<div class="row my-3">
				<div class="col p-0 rounded">
					<table class="table m-0 infotable table-striped shadow">
						<tbody>
							<tr *ngIf="selectedProduct.platform != undefined">
								<td class="text-right text-wrap text-break" translate>
									product_page.platform
								</td>
								<td class="text-left">
									{{ selectedProductPlatform | translate }}
								</td>
							</tr>
							<tr>
								<td class="text-right text-wrap text-break" translate>
									product_page.product_type
								</td>
								<td class="text-left" translate>
									product_page.{{ selectedProduct.item_type_id }}
								</td>
							</tr>
							<tr *ngIf="selectedProduct.platform === 'mipro'">
								<td class="text-right text-wrap text-break" translate>
									product_page.pro_supported
								</td>
								<td class="text-left">
									{{ selectedProduct.product_pro_supported_version_from }}
								</td>
							</tr>
							<tr>
								<td class="text-right" translate>product_page.author</td>
								<td class="text-left text-wrap text-break">
									{{ selectedProduct.author }}
								</td>
							</tr>
							<tr
								*ngIf="
									selectedProduct !== undefined &&
									selectedProduct !== null &&
									selectedProduct.website !== null &&
									selectedProduct.website !== ''
								"
							>
								<td class="text-right" translate>product_page.website</td>
								<td class="text-left text-wrap text-break">
									<a href="{{ selectedProduct.website }}" target="_blank" translate>{{
										selectProductWebsiteDomain
									}}</a>
								</td>
							</tr>
							<tr>
								<td class="text-right" translate>product_page.version</td>
								<td class="text-left">{{ selectedProduct.version }}</td>
							</tr>
							<tr>
								<td class="text-right" translate>product_page.published_date</td>
								<td class="text-left">
									{{ selectedProduct.modified_datetime_stamp | date }}
								</td>
							</tr>
							<tr>
								<td class="text-right" translate>product_page.categories</td>
								<td class="text-left">
									<span>{{ selectedProductCategoryString }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col mx-3">
			<div class="row">
				<div class="col description border rounded align-tems-left p-2">
					<span style="white-space: pre-wrap">{{
						selectedProduct.product_long_description
					}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Lightbox modal overlay-->
<div
	id="myModal"
	class="modal_snapshots"
	*ngIf="selectedProduct !== undefined && selectedProduct !== null"
>
	<div class="container">
		<div class="row text-right">
			<div class="col">
				<span class="closemodal_snapshots" (click)="closeModal()">&times;</span>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="modal-content_snapshots">
					<div
						class="mySlides"
						*ngFor="
							let fullSizeScreenshot of selectedProduct.snapshots_urls;
							let i = index
						"
					>
						<div class="numbertext">
							{{ i + 1 }}/{{ selectedProduct.snapshots_urls.length }}
						</div>
						<img src="{{ fullSizeScreenshot }}" style="width: 100%" />
					</div>

					<span class="prev" (click)="plusSlides(-1)">&#10094;</span>
					<span class="next" (click)="plusSlides(1)">&#10095;</span>

					<div class="caption-container">
						<p id="caption"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
