<div class="needs-validation was-validated" novalidate>
	<form>
		<div class="row align-items-end">
			<div class="col-auto">
				<label for="ProductIcon" translate>AddProductStep2.ProductIcon</label>
				<app-drag-drop-file-control
					[dragDropMessage]="dragDropMessage"
					[dragDropFileTypeMessage]="dragDropFileTypeMessage"
					[fileType]="dragDropfileType"
					[file]="productIconFile"
					[thumbnailSize]="true"
					[fileType]="dragDropfileType"
					(messageToEmit)="getFileFromControl($event)"
				></app-drag-drop-file-control>
			</div>
			<div class="col text-left">
				<img
					class="img-thumbnail"
					[src]="productIconSrc"
					*ngIf="productIconSrc"
					style="max-height: 192px; max-width: 192px; width: auto; height: auto"
				/>
			</div>
		</div>
		<div class="row align-items-end">
			<div class="col">
				<label
					for="id-languageDropdown"
					title="{{ languageAddedToolTip }}"
					translate
					>AddProductStep2.Language</label
				>
				<label title="{{ languageAddedToolTip }}" translates
					>&nbsp;{{ languageAddedText }}</label
				>
				<select
					class="form-control"
					id="id-languageDropdown"
					title="{{ languageAddedToolTip }}"
					[(ngModel)]="language.str"
					(change)="changeLanguage()"
					#languageText="ngModel"
					name="language"
					required
				>
					<option *ngFor="let item of selectedLanguages" [value]="item" translate>
						selectedLanguageName.{{ item }}
					</option>
				</select>
			</div>
			<div class="col-auto">
				<button
					class="btn btn-primary"
					matRipple
					type="button"
					(click)="clearLanguage()"
					translate
				>
					AddProductStep2.ClearLanguage
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<label for="id-product-name" translate
					>AddProductStep2.ProductName
					<span class="required" translate>AddProductStep2.Required</span>
				</label>
				<input
					class="form-control"
					id="id-product-name"
					[(ngModel)]="productItem.product_name"
					#product_name="ngModel"
					name="product_name"
					(change)="updateOtherLanguageInfo()"
					type="text"
					required
				/>
				<div class="invalid-feedback" translate>
					AddProductStep2.ProductNameRequired
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<label for="id-short-desc" translate>AddProductStep2.ShortDesc</label>
				<textarea
					name="counterTest"
					id="id-short-desc"
					class="form-control"
					(change)="updateOtherLanguageInfo()"
					placeholder="{{
						'AddProductStep2.ShortDescriptionPlaceholder' | translate
					}}"
					[(ngModel)]="productItem.product_short_description"
					#product_short_description="ngModel"
					name="product_short_description"
					maxlength="255"
					rows="5"
				></textarea>
				<div class="text-area-counter">
					{{ shortDescAreaMaxChar - productItem.product_short_description.length }}
					characters remaining
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<label for="id-long-desc" translate>AddProductStep2.ProductDesc</label>
				<textarea
					name="counterTest"
					id="id-long-desc"
					class="form-control"
					(change)="updateOtherLanguageInfo()"
					placeholder="{{
						'AddProductStep2.LongDescriptionPlaceholder' | translate
					}}"
					[(ngModel)]="productItem.product_long_description"
					#product_long_description="ngModel"
					name="product_long_description"
					maxlength="{this.globals.descriptionCharLimit}"
					rows="5"
				></textarea>
				<div class="text-area-counter">
					{{ productDescAreaMaxChar - productItem.product_long_description.length }}
					characters remaining
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<label for="id-counterTest" translate>AddProductStep2.WhatsNew</label>
				<textarea
					name="counterTest"
					id="id-counterTest"
					class="form-control"
					(change)="updateOtherLanguageInfo()"
					placeholder="{{ 'AddProductStep2.WhatsNewPlaceholder' | translate }}"
					[(ngModel)]="productItem.whats_new"
					#product_long_description="ngModel"
					name="whats_new"
					maxlength="{this.globals.descriptionCharLimit}"
					rows="5"
				></textarea>
				<div class="text-area-counter">
					{{ whatsNewAreaMaxChar - productItem.whats_new.length }} characters
					remaining
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<label for="id-publisher" translate
					>AddProductStep2.Publisher
					<span class="required" translate>AddProductStep2.Required</span>
				</label>
				<select
					class="form-control"
					id="id-publisher"
					[(ngModel)]="productItem.product_publisher_user_id"
					#product_publisher_user_id="ngModel"
					name="product_publisher_user_id"
					required
				>
					<option *ngFor="let publisher of publisherList" [value]="publisher.value">
						{{ publisher.displayName }}
					</option>
				</select>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<label for="id-appVer" translate
					>AddProductStep2.AppVersion
					<span class="required" translate>AddProductStep2.Required</span>
				</label>
				<input
					class="form-control"
					id="id-appVer"
					[(ngModel)]="productItem.version"
					#version="ngModel"
					pattern="{{ appVersionPattern }}"
					ng-pattern-restrict
					name="version"
					type="text"
					required
				/>
				<div class="invalid-feedback" translate>
					AddProductStep2.AppVersionRequired
				</div>
			</div>
			<div class="col">
				<label for="id-miproVer" translate
					>AddProductStep2.MIPROVersion
					<span class="required" translate>AddProductStep2.Required</span>
				</label>
				<select
					class="form-control"
					id="id-miproVer"
					[(ngModel)]="productItem.product_pro_supported_version_from"
					#product_pro_supported_version_from name="mapInfoVersion"
					name="product_pro_supported_version_from"
					required
					>
					<option
						*ngFor="let miproVersion of miproVerions"
						[value]="miproVersion.major_version"
					>
						{{ miproVersion.display_name }}
					</option>
				</select>
			</div>
		</div>

		<!--  
			<div class="dropdown-item" *ngFor="let cat of categories">
				<a>{{ cat.category_name }}</a>
			</div>
			-->
		<div class="row">
			<div class="col">
				<label for="id-categories" translate>AddProductStep2.Categories</label>
				<ng-select
					id="id-categories"
					[items]="categories"
					[multiple]="true"
					bindLabel="category_name"
					[selectableGroup]="true"
					[closeOnSelect]="true"
					bindValue="category_id"
					[(ngModel)]="productItem.product_category_tags_id"
					name="categories"
				>
					<!--<ng-template ng-option-tmp
               let-item="item"
               let-item$="item$"
               let-index="index">
					<input id="item-{{index}}"
						type="checkbox"
						[ngModel]="item$.selected" /> {{item.name}}
					</ng-template>-->
				</ng-select>
			</div>
			<div class="col">
				<label for="id-categories" translate>header.platform_link</label>
				<select
					class="form-control"
					id="id-platform"
					[(ngModel)]="productItem.platform"
					#languageText="ngModel"
					name="language"
					required
				>
					<option *ngFor="let item of platform" [value]="item.id" translate>
						{{ item.display_name }}
					</option>
				</select>
				<!--ng-select
					id="id-platform"
					[items]="this.globals.platform"
					[multiple]="true"
					bindLabel="display_name"
					[selectableGroup]="true"
					[closeOnSelect]="true"
					bindValue="id"
					[(ngModel)]="productItem.platform"
					name="platform"
				
				</ng-select-->
			</div>
		</div>
	</form>
</div>
