<!-- Drag and Drop Area -->
<div
	id="dragdrop-cntrl"
	[ngStyle]="{
		'min-height': thumbnailSize ? '0' : 'calc(100vh - 394px)',
		height: thumbnailSize ? '192px' : 'auto',
		width: thumbnailSize ? '192px' : 'auto'
	}"
	class="uploadFile"
	(drop)="onDrop($event)"
	(dragover)="onDragOver($event)"
	(dragenter)="onDragEnter($event)"
>
	<div class="uploadFileArea">
		<!-- Spinner on file drop -->
		<div class="fileUpload" *ngIf="fileAdded">
			<div>
				<i class="nc-icon-mini loader_circle-04 spin x3"></i>
			</div>
			<div class="file-name-align">
				<div *ngIf="thumbnailSize">
					<i class="pbi-icon-outline pbi-file"></i>
				</div>
				<div *ngIf="!thumbnailSize">
					<i class="pbi-icon-outline pbi-file x4"></i>
				</div>

				<label translate>DragNDropControl.FileAdded</label>
				<label class="uploadFileText" translate>
					{{ fileName }}
				</label>
			</div>
			<div *ngIf="fileType === 'application/x-zip-compressed'">
				<label translate>DragNDropControl.DonotRefresh</label>
			</div>
		</div>
		<div *ngIf="!fileAdded">
			<div class="fileDropIcon">
				<div *ngIf="thumbnailSize">
					<i class="pbi-icon-outline pbi-download"></i>
				</div>
				<div *ngIf="!thumbnailSize">
					<i class="pbi-icon-outline pbi-download x4"></i>
				</div>
			</div>
			<div>
				<label translate>{{ dragDropMessage }}</label>
			</div>
			<input
				type="file"
				#inputElement
				id="{{ idInputControl }}"
				translate
				name="upload"
				style="visibility: hidden; width: 1px; height: 1px"
				(change)="customUpload($event.target.files)"
				class="hideFileUpload"
				accept="{{ fileType }}"
			/>
			<a href="" (click)="openFileBrowser($event, inputElement)" translate
				>DragNDropControl.ClickHere</a
			>
			<br />
			<label translate>{{ dragDropFileTypeMessage }}</label>
		</div>
		<div *ngIf="!fileAdded">
			<div *ngIf="fileType === 'application/x-zip-compressed'">
				<div>
					<label translate>DragNDropControl.MBXZipInfo</label>
				</div>
				<div>
					<label translate>DragNDropControl.WhitesapceWarning</label>
				</div>
			</div>
		</div>
		<div>
			<br />
			<button
				class="btn btn-secondary"
				style="width: auto"
				*ngIf="fileAdded"
				(click)="Cancel()"
				translate
			>
				DragNDropControl.Cancel
			</button>
		</div>
	</div>
</div>
