import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductUrlService } from "./product-url.service";
import { ProductListService } from "./product-list.service";

@Injectable({
	providedIn: "root"
})
export class ProductDownloadService {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productUrlService: ProductUrlService,
		private productService: ProductListService
	) {}

	async getProductDownloadurl(productId: string) {
		const selectedProduct = await this.productService.getProduct(productId);

		if (selectedProduct == null || selectedProduct === undefined) {
			this.router.navigate(["/404"]);
		} else {
			const selectedProductUrl = await this.productUrlService.getProductUrl(
				selectedProduct.product_id,
				selectedProduct.version
			);
			return selectedProductUrl.presignedURL;
		}
	}
}
