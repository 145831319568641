<app-promo-hero *ngIf="showPromo"></app-promo-hero>

<div class="container-fluid mx-8 mb-3">
	<button
		*ngIf="showAdminControls"
		class="btn btn-primary"
		matRipple
		type="button"
		(click)="navigateToAddProduct()"
	>
		Add Product
	</button>

	<app-product-group
		[productItems]="featuredProducts()"
		[sectionTitle]="'products.featured' | translate"
		[overlayPrefix]="'fp'"
		[isLoading]="productsLoading"
		*ngIf="
			categoryId == null &&
			categoryId == undefined &&
			platformId == null &&
			platformId == undefined
		"
	>
	</app-product-group>

	<div
		class="mx-0"
		*ngIf="selectedCategory !== undefined && selectedCategory !== null"
	>
		<div class="row mx-0">
			<h1>{{ selectedCategory.category_name }}</h1>
		</div>
		<app-product-group
			[productItems]="productsByPlatform('mipro')"
			[sectionTitle]="'platform.mipro' | translate"
			[overlayPrefix]="'mipro'"
			[isLoading]="productsLoading"
		>
		</app-product-group>
		<app-product-group
			[productItems]="productsByPlatform('spectrumspatial')"
			[sectionTitle]="'platform.spectrumspatial' | translate"
			[overlayPrefix]="'spectrumspatial'"
			[isLoading]="productsLoading"
		>
		</app-product-group>
		<app-product-group
			[productItems]="productsByPlatform('mapxtreme')"
			[sectionTitle]="'platform.mapxtreme' | translate"
			[overlayPrefix]="'mapxtreme'"
			[isLoading]="productsLoading"
		>
		</app-product-group>
	</div>
	<div *ngIf="selectedCategory === undefined || selectedCategory === null">
		<app-product-group
			*ngIf="platformId === undefined || platformId === null"
			[productItems]="filterMarketClient()"
			[sectionTitle]="'products.all_products' | translate"
			[overlayPrefix]="'ap'"
			[isLoading]="productsLoading"
		>
		</app-product-group>
		<app-product-group
			*ngIf="platformId !== undefined && platformId !== null"
			[productItems]="filterMarketClient()"
			[sectionTitle]="'platform.' + platformId | translate"
			[overlayPrefix]="'ap'"
			[isLoading]="productsLoading"
		>
		</app-product-group>
	</div>
</div>
