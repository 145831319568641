import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Globals } from "@app/globals";
import { environment } from "@env/environment";
import { ApiResponse } from '@app/ApiMessages/ApiResponse';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ProductDeleteService {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private globals: Globals,
    private translate: TranslateService,
  ) { }

  async deleteProduct(productId: string, version: string) {

    try {
      if (productId == null || productId === undefined || version == null || version === undefined) {
        return;
      }
      this.globals.productListCacheRefresh = true;
      this.globals.productByVersionCacheRefresh = true;
      this.globals.productCacheRefresh = true;
      let urlValue =
        environment.ApiUrl +
        environment.ApiVersion +
        environment.ProductListEndpoint;
      urlValue = this.globals.AddParamToUrl(urlValue, "product_id", productId);
      urlValue = this.globals.AddParamToUrl(urlValue, "version", version);
      await this.http.delete(urlValue).toPromise().then(() => {
        // delete data from S3
        let deleteProductUrlValue =
          environment.ApiUrl +
          environment.ApiVersion +
          environment.DeleteProduct;
        deleteProductUrlValue = this.globals.AddParamToUrl(deleteProductUrlValue, "product_id", productId);
        deleteProductUrlValue = this.globals.AddParamToUrl(deleteProductUrlValue, "version", version);
        this.http.delete(deleteProductUrlValue).toPromise().then(() => { }, (err: HttpErrorResponse) => {
          this.globals.HttpErrorResponseHandling(err.status);
          throw new Error(this.translate.instant(
            "product_delete.error_delete_s3"
          ));
        });
      }, (err: HttpErrorResponse) => {
        this.globals.HttpErrorResponseHandling(err.status);
        throw new Error(this.translate.instant("product_delete.error_delete_database"));
      });
    } catch (exp) {
      throw (exp);
    }
  }
}
