import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";
import { Globals } from "@app/globals";
import { OtherLanguageInfo, ProductItem } from "@app/Models/ProductItem";
import { ProductUrl } from "@app/Models/ProductUrl";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProductUploadService {
  constructor(private http: HttpClient,
    private globals: Globals,
    private translate: TranslateService) { }


  async deleteFile(fileName: string,
    productId: string,
    productVersion: string | null, contentType: string) {
    if (productId != null && productId !== undefined && contentType != null) {
      const presignedPostUrl = await this.getProductUploadPresignedUrl(productId,
        productVersion, fileName, true);
      if (presignedPostUrl.presignedURL != null) {
        const x1 = this.http.delete(presignedPostUrl.presignedURL,
          { headers: { "Content-Type": contentType } }).toPromise().then(() => {
            this.globals.productListCacheRefresh = true;
            this.globals.productByVersionCacheRefresh = true;
            this.globals.productCacheRefresh = true;
          }, (error: HttpErrorResponse) => {
            this.globals.HttpErrorResponseHandling(error.status);
            throwError(this.translate.instant("product_upload.error_upload"));
          });
      } else {
        throwError(this.translate.instant("product_upload.error_presigned_url"));
      }
    } else {
      throwError(this.translate.instant("product_upload.error_incorrect_version"));
    }
  }
  async uploadFile(file: File,
    fileName: string,
    productId: string,
    productVersion: string | null
  ) {
    if (file == null || file === undefined) {
      console.log("Invalid or null file.");
      return;
    }
    if (productId != null && productId !== undefined && file != null) {
      const presignedPostUrl = await this.getProductUploadPresignedUrl(productId,
        productVersion, fileName, false);
      if (presignedPostUrl.presignedURL != null) {
        const x1 = this.http.put(presignedPostUrl.presignedURL, file,
          { headers: { "Content-Type": file.type } }).toPromise().then(() => {
            this.globals.productListCacheRefresh = true;
            this.globals.productByVersionCacheRefresh = true;
            this.globals.productCacheRefresh = true;
          }, (error: HttpErrorResponse) => {
            this.globals.HttpErrorResponseHandling(error.status);
            throwError(this.translate.instant("product_upload.error_upload"));
          });

      } else {
        throwError(this.translate.instant("product_upload.error_presigned_url"));
      }
    } else {
      throwError(this.translate.instant("product_upload.error_incorrect_version"));
    }

  }
  async getProductUploadPresignedUrl(
    productId: string,
    productVersion: string | null,
    fileName: string,
    deletefile: boolean
  ): Promise<ProductUrl> {
    if (productId != null && productId !== undefined && fileName != null) {
      let urlValue =
        environment.ApiUrl +
        environment.ApiVersion +
        environment.ProductUploadUrlEndPoint;

      urlValue = this.globals.AddParamToUrl(urlValue, "product_id", productId);

      if (productVersion != null && productVersion !== undefined) {
        urlValue = this.globals.AddParamToUrl(urlValue, "version", productVersion);
      } else {
        urlValue = this.globals.AddParamToUrl(urlValue, "version", "1.0");
      }
      urlValue = this.globals.AddParamToUrl(urlValue, "filename", fileName);
      if (deletefile) {
        urlValue = this.globals.AddParamToUrl(urlValue, "verb", "delete");
      }

      let x: any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
        (err: HttpErrorResponse) => {
          this.globals.HttpErrorResponseHandling(err.status);
        });
      return JSON.parse(x.body);
    } else {
      return new ProductUrl();
    }
  }

  async putProductMetadata(productItem: ProductItem,
    locale_code: string,
    otherLanguageInfo: Map<string, OtherLanguageInfo>) {
    await this.CreateAndUpdateProductMetadata(productItem,
      null,
      locale_code,
      otherLanguageInfo,
      false);

  }

  async postProductMetadata(productItem: ProductItem,
    product_id: string,
    locale_code: string,
    otherLanguageInfo: Map<string, OtherLanguageInfo>) {
    await this.CreateAndUpdateProductMetadata(productItem,
      product_id,
      locale_code,
      otherLanguageInfo,
      true).then(() => {
        return;
      });
  }
  async UpdateLanguageText(otherLanguageInfo: Map<string, OtherLanguageInfo>,
    locale_code: string,
    urlValue: string) {
    otherLanguageInfo.forEach((value: OtherLanguageInfo, key: string) => {
      if (locale_code !== key) {
        this.http.put<ApiResponse>(urlValue, {
          "product_name": value.product_name,
          "product_long_description": value.product_long_description,
          "product_short_description": value.product_short_description,
          "whats_new": value.whats_new,
          "locale_code": key
        }).toPromise().then(() => {
        }, (err: HttpErrorResponse) => {
          this.globals.HttpErrorResponseHandling(err.status);
          throwError(this.translate.instant("product_upload.error_add_update_langauage_info"));
        });
      }
    });
  }
  async CreateAndUpdateProductMetadata(productItem: ProductItem,
    product_id: string,
    locale_code: string,
    otherLanguageInfo: Map<string, OtherLanguageInfo>,
    bCreate) {
    try {
      if (productItem.item_type_id == undefined || productItem.item_type_id.length == 0) {
        productItem.item_type_id = "Addin";
      }
      if (!bCreate) {
        product_id = productItem.product_id;
      }
      let urlValue = environment.ApiUrl + environment.ApiVersion + environment.ProductListEndpoint;

      if (!bCreate) {
        // update
        urlValue = this.globals.AddParamToUrl(urlValue, "product_id", product_id);
        urlValue = this.globals.AddParamToUrl(urlValue, "version", productItem.version);
        await this.http.put<ApiResponse>(urlValue, {
          "product_isfeatured": productItem.product_isfeatured,
          "product_name": productItem.product_name,
          "product_long_description": productItem.product_long_description,
          "product_short_description": productItem.product_short_description,
          "item_type_id": productItem.item_type_id,
          "product_publisher_user_id": productItem.product_publisher_user_id,
          "product_category_tags_id": productItem.product_category_tags_id,
          "product_pro_supported_version_from": productItem.product_pro_supported_version_from,
          "whats_new": productItem.whats_new,
          "platform" : productItem.platform,
          "locale_code": locale_code
        }).toPromise().then(() => {
          // Update other language text
          this.UpdateLanguageText(otherLanguageInfo, locale_code, urlValue).then(() => {
            this.globals.productListCacheRefresh = true;
            this.globals.productByVersionCacheRefresh = true;
            this.globals.productCacheRefresh = true;
            this.globals.WindowAlertErrorMessage(this.translate.instant("my_products.product_metadata_updated"));
          });
        }, (err: HttpErrorResponse) => {
          this.globals.HttpErrorResponseHandling(err.status);
          throwError(this.translate.instant("product_upload.error_updating_product_metadata"));
        });
      } else {
        await this.http.post<ApiResponse>(urlValue, {
          "product_id": product_id,
          "version": productItem.version,
          "product_isfeatured": "false",
          "product_name": productItem.product_name,
          "product_long_description": productItem.product_long_description,
          "product_short_description": productItem.product_short_description,
          "item_type_id": productItem.item_type_id,
          "product_publisher_user_id": productItem.product_publisher_user_id,
          "product_category_tags_id": productItem.product_category_tags_id,
          "product_pro_supported_version_from": productItem.product_pro_supported_version_from,
          "whats_new": productItem.whats_new,
          "platform" : productItem.platform,
          "locale_code": locale_code
        }).toPromise().then(() => {
          // Update other language text
          // add product_id and version in url
          urlValue = this.globals.AddParamToUrl(urlValue, "product_id", product_id);
          urlValue = this.globals.AddParamToUrl(urlValue, "version", productItem.version);
          this.UpdateLanguageText(otherLanguageInfo, locale_code, urlValue).then(() => {
            this.globals.productListCacheRefresh = true;
            this.globals.productByVersionCacheRefresh = true;
            this.globals.productCacheRefresh = true;
            this.globals.WindowAlertErrorMessage(this.translate.instant("my_products.product_metadata_added"));
          });

        }, (err: HttpErrorResponse) => {
          this.globals.HttpErrorResponseHandling(err.status);
          throw new Error(this.translate.instant("my_products.error_adding_product"));
        });
      }
    } catch (exp) {
      throw (exp);
    }
  }
}
