<div
	class="hero-bgimage d-flex flex-column align-items-center justify-content-between"
	*ngIf="showHero"
	[ngStyle]="{
		'background-image':
			'linear-gradient(135deg, rgba(57, 0, 107, 0.9), rgba(140, 132, 143, 0.9)), url(' +
			heroContent.backgorund_url +
			')'
	}"
>
	<div class="d-flex flex-column align-items-center justify-content-between">
		<div
			class="d-flex flex-column flex-md-row justify-content-start align-items-center"
		>
			<div class="text-center">
				<div>
					<h3 class="text-white">{{ heroContent.title }}</h3>
					<p class="text-white">{{ heroContent.promo_hero_title }}</p>
				</div>
				<div *ngIf="!heroContent.showReadMore">
					<a [routerLink]="['/product/', heroContent.product_id]" class="text-white"
						><u>{{ heroContent.read_more_text }}</u></a
					>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-column align-items-center mt-4">
		<button
			id="btnGotIt"
			class="btn btn-secondary mb-3"
			style="min-width: 7rem;"
			(click)="gotIt()"
		>
			Got It
		</button>
		<p>
			<a class="text-white" href="#" (click)="remindMe($event)">Remind me later</a>
		</p>
	</div>
</div>
