import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.sass"]
})
export class AppComponent {
	title = "MapInfo Pro App Store";

	constructor(private translate: TranslateService) {
		translate.addLangs([
			"cs",
			"da",
			"es",
			"fi",
			"he",
			"it",
			"ja",
			"nl",
			"pl",
			"pt",
			"ru",
			"sv",
			"tr",
			"zh-cn",
			"en",
			"fr",
			"de"
		]);
		translate.setDefaultLang("en");
	}
}
