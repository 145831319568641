import { Injectable } from "@angular/core";
import { PromoHero } from "@app/Models/PromoHero";
import { environment } from "@env/environment";
import { Globals } from "@app/globals";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiResponse } from "@app/ApiMessages/ApiResponse";

@Injectable({
	providedIn: "root"
})
export class PromoHeroService {
	constructor(private globals: Globals, private http: HttpClient) {}

	async getPromoInfo(): Promise<PromoHero[]> {
		let urlValue =
			environment.ApiUrl + environment.ApiVersion + environment.PromoHeroEndpoint;

		urlValue = this.globals.AddLocaleParamToUrl(urlValue);

		let x:any = await this.http.get<ApiResponse>(urlValue).toPromise().catch(
			(err: HttpErrorResponse) => {
			  this.globals.HttpErrorResponseHandling(err.status);
		  });
		return JSON.parse(x.body);
	}
}
